import { useResizeObserver } from 'kitchen/hooks/use-resize-observer'
import { type Transition, motion, useReducedMotion } from 'motion/react'
import { useRef } from 'react'

interface AnimateHeightProps {
  children: React.ReactNode
  transition?: Transition
  initial?: boolean
}

function AnimateHeightBase({ children, transition, initial }: AnimateHeightProps) {
  const ref = useRef<HTMLDivElement>(null)
  const observedSize = useResizeObserver(ref)
  const height = observedSize?.height ?? 'auto'

  return (
    <motion.div animate={{ height }} transition={transition} initial={initial}>
      <div ref={ref}>{children}</div>
    </motion.div>
  )
}

export function AnimateHeight(props: AnimateHeightProps) {
  const shouldReduceMotion = useReducedMotion()

  if (shouldReduceMotion) {
    return <>{props.children}</>
  }
  return <AnimateHeightBase {...props} />
}
