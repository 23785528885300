import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import type { CompanyId } from 'api/types/companies'
import { useIsAuthenticated } from 'domains/auth/queries'
import { CacheKey } from 'kitchen/constants'
import type { MutationHookFactory, QueryHookFactory } from 'kitchen/types'
import { authorizedApi, publicApi } from 'kitchen/utils/api'
import { immutableRequestOptions } from 'kitchen/utils/fetch'
import { isObject } from 'kitchen/utils/helpers'
import { getFeatureFlags, joinWaitlist } from './requests'
import type {
  FeatureFlag,
  FeaturesResponse,
  JoinWaitlistPayload,
  JoinWaitlistResponse,
  UseFeatureFlagsOptions,
  UseFeatureWaitlistOptions,
  WaitlistFeatureFlag,
} from './types'
import { selectFeatureFlags, selectFeatureWaitlist } from './utils/selectors'

const useFeatures: QueryHookFactory<CompanyId | undefined, FeaturesResponse> = (
  companyId,
  options
) => {
  const isAuthenticated = useIsAuthenticated()
  const fetch = isAuthenticated ? authorizedApi : publicApi

  return useQuery({
    queryKey: [CacheKey.FEATURES, companyId, isAuthenticated],
    queryFn: ({ signal }) => getFeatureFlags(fetch, { companyId }, signal),
    ...immutableRequestOptions,
    ...options,
  })
}

export const useFeatureFlags = (
  companyId: CompanyId | undefined,
  options?: UseFeatureFlagsOptions
) => {
  return useFeatures<FeatureFlag[]>(companyId, {
    select: selectFeatureFlags,
    ...options,
  })
}

export const useFeatureWaitlist = (
  companyId: CompanyId | undefined,
  options?: UseFeatureWaitlistOptions
) => {
  return useFeatures<WaitlistFeatureFlag[]>(companyId, {
    select: selectFeatureWaitlist,
    ...options,
  })
}

export const useJoinWaitlist: MutationHookFactory<
  JoinWaitlistPayload,
  JoinWaitlistResponse
> = (options) => {
  const queryClient = useQueryClient()
  return useMutation({
    ...options,
    mutationFn: (payload) => joinWaitlist(authorizedApi, payload),
    onSuccess: async (data, variables, context) => {
      await queryClient.invalidateQueries([CacheKey.FEATURES, variables.companyId])
      return options?.onSuccess?.(data, variables, context)
    },
  })
}

export function useHasFeatureFlag(
  input: FeatureFlag[],
  options?: UseFeatureFlagsOptions
): boolean[]
export function useHasFeatureFlag(
  companyId: CompanyId | undefined,
  input: FeatureFlag[],
  options?: UseFeatureFlagsOptions
): boolean[]
export function useHasFeatureFlag(
  arg1?: CompanyId | FeatureFlag[] | undefined,
  arg2?: FeatureFlag[] | UseFeatureFlagsOptions,
  arg3?: UseFeatureFlagsOptions
): boolean[] {
  const companyId = typeof arg1 === 'string' ? arg1 : undefined
  const features = Array.isArray(arg1) ? arg1 : Array.isArray(arg2) ? arg2 : []
  const options = isObject(arg3)
    ? arg3
    : isObject(arg2) && !Array.isArray(arg2)
    ? arg2
    : {}
  const featureFlags =
    useFeatureFlags(companyId, { suspense: true, ...options }).data ?? []
  return features.map((flag) => featureFlags.includes(flag))
}
