import { PaymentMethodBase } from 'domains/account/components'
import { useSendInteraction, useTrackUIView } from 'domains/analytics/hooks'
import type { CompanyId } from 'domains/company/types'
import { useHasFeatureFlag } from 'domains/feature-flag/queries'
import { useUpdateGetPaidPayrun } from 'domains/payrun/queries'
import type { PayrunId } from 'domains/payrun/types'
import { FormattedMessage } from 'react-intl'
import { Chip, StepHeader } from 'salad/components'
import * as Icons from 'salad/icons'
import { StepLayout } from 'salad/layouts'
import { VStack } from 'salad/primitives'
import { ApronFooter } from '../../../components'
import { Fees } from '../../../constants'
import type { PaymentFlowValues, PaymentMethodStepValues } from '../types'

interface PaymentMethodStepProps {
  payrunId: PayrunId
  companyId: CompanyId
  onContinue: (values: Partial<PaymentFlowValues> & PaymentMethodStepValues) => void
  onBack: () => void
}

export const PaymentMethodStep = ({
  onBack,
  onContinue,
  payrunId,
  companyId,
}: PaymentMethodStepProps) => {
  const [hasGetPaidCardPayment] = useHasFeatureFlag(companyId, ['GET_PAID_CARD_PAYMENT'])
  const sendInteraction = useSendInteraction(undefined)
  const updateGetPaidPayrun = useUpdateGetPaidPayrun()

  useTrackUIView({
    companyId: undefined,
    data: {
      target: 'get-paid-flow',
      step: 'payment-method',
    },
  })

  return (
    <StepLayout.Root css={{ gridTemplateRows: 'auto auto 1fr' }}>
      <StepLayout.Header>
        <StepHeader.Root onBack={onBack}>
          <StepHeader.Title>
            <FormattedMessage
              id="get-paid.payment.payment-method.title"
              defaultMessage="Choose your payment method"
            />
          </StepHeader.Title>
        </StepHeader.Root>
      </StepLayout.Header>
      <VStack gap={16}>
        <PaymentMethodBase.Root
          surface="card"
          disabled={updateGetPaidPayrun.isLoading}
          onClick={async () => {
            const payrun = await updateGetPaidPayrun.mutateAsync({
              payrunId,
              instrumentType: 'PLAID',
            })
            sendInteraction({
              type: 'click',
              target: 'get-paid-payment-method',
              variant: 'PLAID',
            })

            onContinue({ instrumentType: 'PLAID', payrun })
          }}
        >
          <PaymentMethodBase.Start>
            <Icons.S32.Bank />
          </PaymentMethodBase.Start>
          <PaymentMethodBase.Title>
            <FormattedMessage
              id="get-paid.payment.payment-method.option.bank"
              defaultMessage="Pay by bank"
            />
          </PaymentMethodBase.Title>
        </PaymentMethodBase.Root>
        <PaymentMethodBase.Root
          surface="card"
          disabled={!hasGetPaidCardPayment || updateGetPaidPayrun.isLoading}
          onClick={async () => {
            if (hasGetPaidCardPayment) {
              const payrun = await updateGetPaidPayrun.mutateAsync({
                payrunId,
                instrumentType: 'CARD',
              })
              sendInteraction({
                type: 'click',
                target: 'get-paid-payment-method',
                variant: 'CARD',
              })
              onContinue({ instrumentType: 'CARD', payrun })
            }
          }}
        >
          <PaymentMethodBase.Start>
            <Icons.S32.Card />
          </PaymentMethodBase.Start>
          <PaymentMethodBase.Title>
            <FormattedMessage
              id="get-paid.payment.payment-method.option.card"
              defaultMessage="Pay by card"
            />
          </PaymentMethodBase.Title>
          <PaymentMethodBase.End>
            <Chip variant="neutral">
              <FormattedMessage
                id="get-paid.payment.payment-method.option.card.fee"
                defaultMessage="{fee}% fee"
                values={{ fee: Fees.CARD_PROCESSING_FEE_PERCENTAGE }}
              />
            </Chip>
          </PaymentMethodBase.End>
        </PaymentMethodBase.Root>
      </VStack>
      <ApronFooter />
    </StepLayout.Root>
  )
}
