import { useRef } from 'react'
import type { Response } from 'ya-fetch'
import { Delay, Time } from '../constants'

/**
 * Sometimes we're sure the data we're fetching won't change in the nearest future.
 * So we don't want to refetch it every time, but rather take it from cache.
 * This set of settings makes refetch happen no sooner than page refresh or 24 hrs
 */
export const immutableRequestOptions = {
  refetchOnMount: false,
  refetchOnReconnect: false,
  staleTime: Time.DAY,
}

export const useDebouncedRequest = () => {
  const timerRef = useRef<ReturnType<typeof setTimeout>>(undefined)

  return <T>(request: () => Promise<T>, signal: AbortSignal | undefined) =>
    new Promise<T>((resolve, reject) => {
      clearTimeout(timerRef.current)
      timerRef.current = setTimeout(() => {
        if (signal?.aborted) {
          reject(signal.reason)
        } else {
          resolve(request())
        }
      }, Delay.INPUT)
    })
}

export async function fetchFile(response: Response, fallbackName?: string) {
  return {
    content: await response.blob(),
    fileName: getFileNameFromContentDisposition(
      response.headers.get('Content-Disposition'),
      fallbackName
    ),
  }
}

export function getFileNameFromContentDisposition(
  value: string | null,
  fallback = 'untitled'
): string {
  if (!value) {
    return fallback
  }
  const filenameMatch = value?.match(/filename="?([^";]+)"?/)
  return filenameMatch ? filenameMatch[1] : fallback
}
