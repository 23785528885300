export const Info = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.15 8C13.15 10.8443 10.8443 13.15 8 13.15C5.15573 13.15 2.85 10.8443 2.85 8C2.85 5.15573 5.15573 2.85 8 2.85C10.8443 2.85 13.15 5.15573 13.15 8ZM15 8C15 11.866 11.866 15 8 15C4.13401 15 1 11.866 1 8C1 4.13401 4.13401 1 8 1C11.866 1 15 4.13401 15 8ZM8.92498 6.42637H7.07498V4.57637H8.92498V6.42637ZM8.92495 11.4236H7.07495V7.42637H8.92495V11.4236Z"
      fill="currentColor"
    />
  </svg>
)
