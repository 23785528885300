import { SerratedEdge } from 'salad/components'
import { VStack, Grid } from 'salad/primitives'
import { styled, theme } from 'salad/stitches'

const WrapperGrid = styled(Grid, {
  gridAutoRows: '1fr auto',
  transition: 'filter 0.15s ease-out, transform 0.15s ease-out',
  variants: {
    variant: {
      default: {
        filter: `drop-shadow(${theme.shadows['elevation-200']}) blur(0)`,
      },
      blurred: {
        transform: 'scale(0.95)',
        filter: `drop-shadow(0px 8px 12px ${theme.colors.transparent}) blur(5px)`,
      },
    },
  },
})

const ContentStack = styled(VStack, {
  transition: 'background-color 0.15s ease-out',
  variants: {
    variant: {
      default: {
        backgroundColor: theme.colors['white'],
      },
      blurred: {
        backgroundColor: theme.colors['grey-10'].toString(),
      },
    },
  },
})

interface PaymentSummaryCardContainerProps {
  children: React.ReactNode
  variant?: 'default' | 'blurred'
}

export const PaymentSummaryCardContainer = ({
  children,
  variant = 'default',
}: PaymentSummaryCardContainerProps) => {
  return (
    <WrapperGrid variant={variant}>
      <ContentStack p={32} variant={variant}>
        {children}
      </ContentStack>
      <SerratedEdge position="bottom" />
    </WrapperGrid>
  )
}
