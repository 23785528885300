import { useMemo } from 'react'
import type { MoneyFormatOptions } from '../../i18n/utils'
import { getMoneyFormat } from '../../i18n/utils'

/** @deprecated import { getMoneyFormat } from 'domains/i18n/utils' instead */
export function useMoneyFormat({
  signDisplay,
  minimumFractionDigits,
  maximumFractionDigits,
}: MoneyFormatOptions = {}) {
  return useMemo(
    () => getMoneyFormat({ signDisplay, minimumFractionDigits, maximumFractionDigits }),
    [signDisplay, minimumFractionDigits, maximumFractionDigits]
  )
}
