import { CompanyIdSchema } from 'domains/company/schemas'
import { GetPaidInstrumentTypeSchema } from 'domains/instrument/schemas'
import * as money from 'domains/money/validations'
import { GetPaidPayrunSchema } from 'domains/payrun/schemas'
import { z } from 'zod'

export const BeneficiaryCompanySchema = z.object({
  id: CompanyIdSchema,
  legalName: z.string(),
  alias: z.string(),
})

export const EntryStepSchema = z.object({
  amount: money.required('Required'),
  billReference: z.string().min(1, 'Required'),
  beneficiaryCompany: BeneficiaryCompanySchema,
})

export const PaymentMethodStepSchema = z.object({
  instrumentType: GetPaidInstrumentTypeSchema.nullable(),
})

export const PayStepSchema = z.object({
  email: z.string().email('Please use a valid email address'),
  payrun: GetPaidPayrunSchema,
  instrumentType: GetPaidInstrumentTypeSchema.nullable(),
})

export const PaymentFlowStepSchema = z.enum([
  'payment-method',
  'pay-by-card',
  'pay-by-plaid',
  'success',
])
