import { StorageKey } from 'kitchen/constants'
import { createId } from 'kitchen/utils/helpers'

export function getSessionId() {
  if (typeof window === 'undefined') {
    return 'SSR'
  }

  const id = window.sessionStorage.getItem(StorageKey.SESSION) ?? createId(36)
  window.sessionStorage.setItem(StorageKey.SESSION, id)

  return id
}

export function resetSessionId() {
  window.sessionStorage.removeItem(StorageKey.SESSION)
  return getSessionId()
}
