import type { GenericComponent } from 'kitchen/types'
import { Spinner } from 'salad/components'
import { ButtonBase, HStack, Image, Text } from 'salad/primitives'
import { styled, theme } from 'salad/stitches'
import { typography } from 'salad/variants'
import GoogleLogoUrl from './google-logo.svg'
import XeroLogoUrl from './xero-logo.png'

interface VendorButtonProps extends React.ComponentProps<typeof VendorButtonBase> {
  isLoading?: boolean
}

const VendorButtonBase = styled(ButtonBase, {
  backgroundColor: theme.colors['transparent'],
  alignContent: 'center',
  borderWidth: 2,
  borderStyle: 'solid',
  borderColor: theme.colors['grey-20'],
  paddingInline: theme.space[12],
  transition: 'border-color 0.2s, background-color 0.2s',
  '&[disabled]': { opacity: '0.4' },
  '@hover': {
    '&:hover': {
      backgroundColor: theme.colors['grey-10'],
    },
  },
  '&:active, &[data-loading="true"]': {
    backgroundColor: theme.colors['grey-15'],
  },
  '[data-spinner]': {
    color: theme.colors['grey-60'],
  },
  variants: {
    size: {
      large: {
        ...typography['headline-h3'],
        borderRadius: theme.radii[16],
        paddingBlock: theme.space[16],
        height: 68,
        '[data-spinner]': {
          size: 32,
        },
      },
      medium: {
        ...typography['button-medium'],
        borderRadius: theme.radii[8],
        width: 'fit-content',
        height: 48,
        paddingBlock: 0,
        '[data-spinner]': {
          size: 24,
        },
      },
    },
    variant: {
      'quickbooks-signup': {
        borderColor: 'rgba(107, 108, 114, 1)',
        color: 'rgba(57, 58, 61, 1)',
        '@hover': {
          '&:hover': {
            backgroundColor: 'rgba(57, 58, 61, 0.1)',
          },
        },
        '&:active, &[data-loading="true"]': {
          backgroundColor: 'rgba(57, 58, 61, 0.15)',
          '[data-spinner]': {
            color: 'rgba(57, 58, 61, 0.5)',
          },
        },
      },
      'quickbooks-login': {
        borderColor: 'rgba(0, 119, 197, 1)',
        color: 'rgba(0, 119, 197, 1)',
        '@hover': {
          '&:hover': {
            backgroundColor: 'rgba(0, 119, 197, 0.10)',
          },
        },
        '&:active, &[data-loading="true"]': {
          backgroundColor: 'rgba(0, 119, 197, 0.15)',
          '[data-spinner]': {
            color: 'rgba(0, 119, 197, 0.5)',
          },
        },
      },
    },
  },
  defaultVariants: {
    size: 'large',
  },
})

const ButtonRoot: GenericComponent<VendorButtonProps> = ({
  isLoading,
  children,
  ...props
}) => {
  return (
    <VendorButtonBase data-loading={isLoading} {...props}>
      <HStack gap="12" css={{ alignItems: 'center', justifyContent: 'center' }}>
        {isLoading ? <Spinner data-spinner /> : children}
      </HStack>
    </VendorButtonBase>
  )
}

const XeroButton: GenericComponent<VendorButtonProps> = ({
  children,
  ...props
}: VendorButtonProps) => {
  return (
    <ButtonRoot {...props}>
      <Image src={XeroLogoUrl} width="32px" height="32px" alt="Xero" />
      <Text>{children}</Text>
    </ButtonRoot>
  )
}
const GoogleButton: GenericComponent<VendorButtonProps> = ({
  children,
  ...props
}: VendorButtonProps) => {
  return (
    <ButtonRoot {...props}>
      <Image src={GoogleLogoUrl} width="32px" height="32px" alt="Google" />
      <Text>{children}</Text>
    </ButtonRoot>
  )
}

export { ButtonRoot as Root, GoogleButton as Google, XeroButton as Xero }
