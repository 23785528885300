import { useTrackPageVisit } from 'domains/analytics/hooks'
import { ExternalResource } from 'kitchen/constants'
import {
  Routes,
  Route,
  createBrowserRouter,
  RouterProvider,
  useRouteError,
  useLocation,
} from 'kitchen/router'
import { Suspense, useEffect } from 'react'
import { LoadingStatus } from './components'
import { Match } from './constants'
import { PaymentFlowPage } from './features/payment-flow'
import { CardChallengeStatusPage } from './features/payment-flow/card-challenge-status-page'

const RedirectToWebsite = () => {
  useEffect(() => {
    const redirectUrl = new URL(ExternalResource.WEBSITE_MAIN)
    redirectUrl.searchParams.append('utm_source', 'get-paid-app-index')
    window.open(redirectUrl, '_self')
  }, [])
  return null
}

const Root = () => {
  const location = useLocation()
  useTrackPageVisit(location.pathname + location.search, 'GET_PAID_PAGE_VISIT')

  return (
    <Routes>
      <Route index element={<RedirectToWebsite />} />
      <Route path={Match.CARD_CHALLENGE} element={<CardChallengeStatusPage />} />
      <Route
        path={Match.COMPANY_ALIAS_NAME}
        element={
          <Suspense fallback={<LoadingStatus />}>
            <PaymentFlowPage />
          </Suspense>
        }
      />
    </Routes>
  )
}

const router = createBrowserRouter([
  {
    path: '*',
    element: <Root />,
    ErrorBoundary: () => {
      throw useRouteError()
    },
  },
])

export const GetPaidRoutes = () => {
  return <RouterProvider router={router} />
}
