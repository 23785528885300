import * as currencies from '@dinero.js/currencies'
import { isNonEmpty } from 'kitchen/utils/helpers'
import { z } from 'zod'

const allCurrencyCodes = Object.keys(currencies).filter(
  /**
   * Filtering out:
   * 1. 'default' and '__esModule' keys that get imported in some build modes;
   * 2. Non-decimal or multi-based currencies. In fact, out app only supports decimal
   *    currencies since we use `toDecimal()` method of dinero.js.
   */
  (currencyCode) => currencies[currencyCode]?.base === 10
)

if (!isNonEmpty(allCurrencyCodes)) {
  throw new Error('Empty currency list')
}

export const CurrencyCodeSchema = z.enum(allCurrencyCodes)

export const MoneySchema = z.object({
  amount: z.number(),
  currency: CurrencyCodeSchema,
})

export const ScaledMoneySchema = MoneySchema.extend({
  scale: z.number().optional(),
})
