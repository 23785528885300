import { useTrackUIView } from 'domains/analytics/hooks'
import { useGetPaidPayrun, useUpdateGetPaidPayrunAuthor } from 'domains/payrun/queries'
import { StorageKey } from 'kitchen/constants'
import { useForm, zodResolver } from 'kitchen/forms'
import type { UUID } from 'kitchen/types'
import { assert, createUUID } from 'kitchen/utils/helpers'
import { useId, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { Input, InputGroup, StepHeader, Surface, Toast } from 'salad/components'
import { StepLayout } from 'salad/layouts'
import { PayStepSchema } from '../schemas'
import type { PayStepValues } from '../types'
import { usePay } from '../use-pay'

interface PayStepProps {
  values: PayStepValues
  onContinue: (values: PayStepValues) => void
  onBack: () => void
}

export const PayByPlaidStep = ({ values, onBack, onContinue }: PayStepProps) => {
  const formId = useId()
  const toast = Toast.useContext()

  const [idempotencyKey] = useState(() => createUUID<UUID>())

  const { handleSubmit, register, formState } = useForm<PayStepValues>({
    defaultValues: values,
    resolver: zodResolver(PayStepSchema),
  })

  useTrackUIView({
    companyId: undefined,
    data: {
      target: 'get-paid-flow',
      step: 'pay',
    },
  })

  const updateGetPaidPayrunAuthor = useUpdateGetPaidPayrunAuthor({
    onError: () => {
      return toast.show(
        <Toast.Root variant="error">
          <Toast.Title>
            <FormattedMessage
              id="get-paid.payment.update-author.error-toast"
              defaultMessage="Failed to update email address. Please try again later."
            />
          </Toast.Title>
        </Toast.Root>
      )
    },
  })

  const getPaidPayrun = useGetPaidPayrun({ payrunId: values.payrun.id })

  const [pay] = usePay({
    onSuccess: async () => {
      const updatedPayrun = await getPaidPayrun.refetch()
      assert(updatedPayrun.data, 'Missing payrun')

      onContinue({
        ...values,
        payrun: updatedPayrun.data,
      })
    },
  })

  async function handleFormSubmit(values: PayStepValues) {
    localStorage.setItem(StorageKey.GET_PAID_PAYER_EMAIL, values.email)

    await updateGetPaidPayrunAuthor.mutateAsync({
      payrunId: values.payrun.id,
      email: values.email,
    })

    pay.mutate({
      instrumentType: 'PLAID',
      payrunId: values.payrun.id,
      idempotencyKey: idempotencyKey,
    })
  }

  return (
    <StepLayout.Root>
      <StepLayout.Header>
        <StepHeader.Root onBack={onBack}>
          <StepHeader.Title>
            <FormattedMessage
              id="get-paid.payment.pay-by-bank-step.title"
              defaultMessage="Provide your email address"
            />
          </StepHeader.Title>
          <StepHeader.Subtitle>
            <FormattedMessage
              id="get-paid.payment.pay-by-bank-step.subtitle"
              defaultMessage="We’ll use this to send you a receipt."
            />
          </StepHeader.Subtitle>
        </StepHeader.Root>
      </StepLayout.Header>
      <Surface
        p="32"
        variant="flat"
        as="form"
        id={formId}
        onSubmit={handleSubmit(handleFormSubmit)}
      >
        <InputGroup.Root>
          <InputGroup.Label>
            <FormattedMessage
              id="get-paid.payment.pay-by-bank-step.input-label.email"
              defaultMessage="Email"
            />
          </InputGroup.Label>
          <Input
            placeholder="example@email.com"
            {...register('email')}
            inputMode="email"
            autoComplete="email"
            autoCapitalize="none"
            aria-invalid={formState.errors.email !== undefined}
          />
          <InputGroup.Message>{formState.errors.email?.message}</InputGroup.Message>
        </InputGroup.Root>
      </Surface>
      <StepLayout.Action type="submit" form={formId} loading={pay.isLoading}>
        <FormattedMessage
          id="get-paid.payment.pay-by-bank-step.submit"
          defaultMessage="Pay"
        />
      </StepLayout.Action>
    </StepLayout.Root>
  )
}
