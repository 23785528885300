export const Person = () => (
  <svg fill="none" width="24" height="24" viewBox="0 0 24 24">
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M16 7a4 4 0 1 1-8 0 4 4 0 0 1 8 0Zm2 0A6 6 0 1 1 6 7a6 6 0 0 1 12 0ZM6 22a6 6 0 0 1 12 0h2a8 8 0 1 0-16 0h2Z"
      clipRule="evenodd"
    />
  </svg>
)
