import type { ApiRequest } from 'kitchen/types'
import type {
  FeaturesPayload,
  FeaturesResponse,
  JoinWaitlistPayload,
  JoinWaitlistResponse,
} from './types'

export const getFeatureFlags: ApiRequest<FeaturesPayload, FeaturesResponse> = (
  fetch,
  payload,
  signal
) => fetch.get(`/features`, { params: payload, signal }).json()

export const joinWaitlist: ApiRequest<JoinWaitlistPayload, JoinWaitlistResponse> = (
  fetch,
  { companyId, ...payload },
  signal
) =>
  fetch.put(`/features/waitlist`, { params: { companyId }, json: payload, signal }).json()
