import { useHasFeatureFlag } from 'domains/feature-flag/queries'
import { subtract, toFloat } from 'domains/money/utils'
import { useGetPaidPayrun } from 'domains/payrun/queries'
import type { GetPaidPayrun, PayrunId } from 'domains/payrun/types'
import {
  generatePath,
  parseAsStringLiteral,
  useNavigate,
  useParams,
  useQueryState,
} from 'kitchen/router'
import { ImpossibleError } from 'kitchen/utils/error'
import { assert } from 'kitchen/utils/helpers'
import { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { Status } from 'salad/components'
import * as Icons from 'salad/icons'
import { StatusLayout } from 'salad/layouts'
import { match } from 'ts-pattern'
import { Path } from '../../constants'
import { PaymentFlowStepSchema } from './schemas'
import {
  PayByCardStep,
  PayByPlaidStep,
  PaymentMethodStep,
  SuccessResultStep,
} from './steps'

import type { BeneficiaryCompany, PaymentFlowStep, PaymentFlowValues } from './types'

interface PaymentFlowState {
  values: PaymentFlowValues
}

interface PaymentFlowBaseProps {
  email: string
  beneficiaryCompany: BeneficiaryCompany
  payrun: GetPaidPayrun
  onBack: () => void
}

function PaymentFlowBase({
  payrun,
  email,
  beneficiaryCompany,
  onBack,
}: PaymentFlowBaseProps) {
  const [hasGetPaidCardPayment] = useHasFeatureFlag(
    beneficiaryCompany.id,
    ['GET_PAID_CARD_PAYMENT'],
    { suspense: false }
  )

  const [step, setStep] = useQueryState(
    'step',
    parseAsStringLiteral(PaymentFlowStepSchema.options).withDefault(
      hasGetPaidCardPayment ? 'payment-method' : 'pay-by-plaid'
    )
  )

  const [flow, setFlow] = useState<PaymentFlowState>({
    values: {
      instrumentType: hasGetPaidCardPayment ? null : 'PLAID',
      beneficiaryCompany,
      payrun,
      email,
    },
  })

  function update(values?: Partial<PaymentFlowValues>) {
    setFlow((prev) => ({ ...prev, values: { ...prev.values, ...values } }))
  }

  function goForward(step: PaymentFlowStep, values: Partial<PaymentFlowValues>) {
    const nextStep = match(step)
      .returnType<PaymentFlowStep>()
      .with('payment-method', () => {
        assert(values.instrumentType)
        return match(values.instrumentType)
          .returnType<PaymentFlowStep>()
          .with('CARD', () => 'pay-by-card')
          .with('PLAID', () => 'pay-by-plaid')
          .exhaustive()
      })
      .with('pay-by-card', () => 'success')
      .with('pay-by-plaid', () => 'success')
      .with('success', () => 'success')
      .exhaustive()

    setStep(nextStep)
  }

  function goBack(step: PaymentFlowStep) {
    match(step)
      .with('payment-method', onBack)
      .with('pay-by-card', () => {
        return setStep('payment-method')
      })
      .with('pay-by-plaid', () => {
        if (hasGetPaidCardPayment) {
          return setStep('payment-method')
        }
        return onBack()
      })
      .with('success', () => {
        return
      })
      .exhaustive()
  }

  switch (step) {
    case 'payment-method':
      return (
        <PaymentMethodStep
          payrunId={payrun.id}
          companyId={beneficiaryCompany.id}
          onContinue={(values) => {
            update(values)
            goForward('payment-method', values)
          }}
          onBack={() => {
            goBack('payment-method')
          }}
        />
      )
    case 'pay-by-plaid':
      return (
        <PayByPlaidStep
          values={flow.values}
          onBack={() => {
            goBack('pay-by-plaid')
          }}
          onContinue={(values) => {
            update(values)
            goForward('pay-by-plaid', values)
          }}
        />
      )
    case 'pay-by-card':
      return (
        <PayByCardStep
          beneficiaryCompany={beneficiaryCompany}
          values={flow.values}
          onBack={() => {
            goBack('pay-by-card')
          }}
          onContinue={(values) => {
            update(values)
            goForward('pay-by-card', values)
          }}
        />
      )
    case 'success':
      return <SuccessResultStep values={flow.values} />

    default:
      throw new ImpossibleError('Unhandled step', step)
  }
}

interface PaymentFlowProps {
  email: string
  beneficiaryCompany: BeneficiaryCompany
}

export const PaymentFlow = ({ beneficiaryCompany, email }: PaymentFlowProps) => {
  const navigate = useNavigate()
  const { payrunId, companyAliasName } = useParams<{
    payrunId: PayrunId
    companyAliasName: string
  }>()
  assert(payrunId)
  assert(companyAliasName)

  const getPaidPayrun = useGetPaidPayrun({ payrunId }, { suspense: true })

  assert(getPaidPayrun.data)

  switch (getPaidPayrun.data.state) {
    case 'CREATED':
      const payrun = getPaidPayrun.data
      const initialAmount = toFloat(subtract(payrun.amount, payrun.fee))
      return (
        <PaymentFlowBase
          email={email}
          beneficiaryCompany={beneficiaryCompany}
          payrun={payrun}
          onBack={() => {
            navigate({
              pathname: generatePath(Path.COMPANY_ALIAS, { companyAliasName }),
              search: new URLSearchParams({
                amount: initialAmount.toString(),
                reference: payrun.reference,
              }).toString(),
            })
          }}
        />
      )
    case 'DECLINED':
      return (
        <StatusLayout.Root>
          <StatusLayout.Content>
            <Status.Root>
              <Status.Icon>
                <Icons.S64.Failure />
              </Status.Icon>
              <Status.Title>
                <FormattedMessage
                  id="get-paid.payment.declined.title"
                  defaultMessage="Payment declined"
                />
              </Status.Title>
              <Status.Description>
                <FormattedMessage
                  id="get-paid.payment.declined.description"
                  defaultMessage="Please create a new one."
                />
              </Status.Description>
              <Status.Action
                onClick={() =>
                  navigate(generatePath(Path.COMPANY_ALIAS, { companyAliasName }))
                }
              >
                <FormattedMessage
                  id="get-paid.payment.declined.action"
                  defaultMessage="Create a new payment"
                />
              </Status.Action>
            </Status.Root>
          </StatusLayout.Content>
        </StatusLayout.Root>
      )
    case 'PAID':
    case 'PAYING':
    case 'PENDING':
      return (
        <SuccessResultStep
          values={{
            beneficiaryCompany,
            email,
            payrun: getPaidPayrun.data,
            instrumentType: null,
          }}
        />
      )
  }
}
