import { styled, theme } from '../../stitches'

const LogoBase = styled('svg', {
  display: 'block',
  height: 'auto',
  maxWidth: '100%',
})

export interface LogoProps extends React.ComponentProps<typeof LogoBase> {
  variant?: 'full' | 'square' | 'small'
}

export function Logo({
  variant = 'full',
  'aria-label': label = 'Apron logo',
  ...rest
}: LogoProps) {
  switch (variant) {
    case 'full':
      return (
        <LogoBase
          role="img"
          width="84"
          height="20"
          viewBox="0 0 84 20"
          fill="currentColor"
          aria-label={label}
          preserveAspectRatio="xMinYMax meet"
          {...rest}
        >
          <path d="M11.275 8.848v-.164c2.025 0 3.458-1.476 3.458-3.591C14.742 2.08 11.433 0 7.567 0 4.375 0 2.042 1.122.408 2.607l1.759 4.937h.275s2-2.503 4.641-2.503c2.034 0 3.825 1.295 3.642 3.392C9.608 7.665 7.9 7.2 6.417 7.2c-3.392 0-6.084 1.71-6.084 4.79 0 2.228 1.834 4.446 4.809 4.446 3.858 0 6.175-3.427 5.9-6.655h.225c.383 2.59-.592 4.661-1.834 6.06v.267h5.7v-.31c-.008-4.009-2.432-5.86-3.808-6.911l-.05-.038ZM7.65 12.18c-1.008 0-1.775-.734-1.775-1.779 0-1.148.892-1.881 2.225-1.881 1.042 0 2.125.535 2.425.725-.317 1.907-1.475 2.935-2.875 2.935ZM60.733 8.356c0 4.462-3.158 8.062-8.283 8.062-5.108 0-8.283-3.608-8.283-8.062 0-4.454 3.208-8.114 8.283-8.114 5 0 8.283 3.651 8.283 8.114Zm-4.708 0c0-1.994-1.6-2.952-3.583-2.952-1.95 0-3.559.958-3.559 2.952s1.575 2.978 3.559 2.978c1.983 0 3.583-.984 3.583-2.978ZM32.267 8.304c0 4.514-2.3 8.114-6.159 8.114-2.525 0-5.083-.829-5.408-6.146h-.3l.55 3.763V20h-5.058V.552h5.258l-.5 5.344h.2c.7-4.463 3.133-5.654 5.258-5.654 3.884 0 6.159 3.547 6.159 8.062ZM27.383 8.2c0-1.994-1.441-2.952-3.333-2.952-1.867 0-3.333.958-3.333 2.952 0 2.046 1.466 2.978 3.333 2.978 1.892 0 3.333-.932 3.333-2.978ZM38 9.391h-.25l.9-8.839h-5.333v15.555h5.233v-4.359c0-3.427 1.05-5.473 3-5.706.65-.051 1.225.078 1.8.518h.275V.242c-3.275 0-5.1 2.045-5.625 9.15ZM80.617 10.583c-1.825 0-3.05 1.32-3.05 2.926 0 1.58 1.2 2.9 3.05 2.9 1.875 0 3.05-1.32 3.05-2.9 0-1.597-1.225-2.926-3.05-2.926ZM75.9 16.098c.292-1.096.967-3.85.967-7.639 0-4.255-1.459-8.217-5.225-8.217-3.009 0-4.834 2.46-5.434 9.15h-.25l.9-8.84h-5.333v15.555h5.233v-3.211c0-3.815.425-6.62 2.534-6.62 1.533 0 2.6 1.311 2.6 3.728 0 2.184-.459 4.135-1.292 5.87v.233l5.3-.009Z" />
        </LogoBase>
      )
    case 'small':
      return (
        <LogoBase
          role="img"
          width="40"
          height="40"
          viewBox="0 0 48 48"
          fill="currentColor"
          aria-label={label}
          {...rest}
        >
          <path d="M26.412 35.541H36c-.244-4.659-2.166-8.165-6.702-9.984.015-.051-.007-.148.053-.176 2.35-1.098 3.698-4.242 3.707-6.035.01-1.744-.156-2.85-1.424-4.106-.981-.97-2.299-1.604-3.581-2.084a18.61 18.61 0 0 0-4.865-1.088c-1.38-.123-2.777-.087-4.14.171-1.541.293-3.019.867-4.39 1.617a19.842 19.842 0 0 0-2.447 1.587c-.208.157-.261.152-.164.395.165.412.388.81.58 1.21l1.496 3.12.735 1.532.368.768c.041.086.41.714.37.77.071-.098.575-.766.623-.832 1.06-1.461 2.406-2.573 4.108-3.232 1.696-.656 3.418-.773 5.1.008 2.181 1.014 3.275 2.794 3.5 5.13.028.294.003.592.003.853-.524-.418-1.01-.866-1.555-1.23-1.703-1.136-3.618-1.611-5.65-1.647-1.875-.033-3.673.316-5.321 1.221-2.605 1.431-4.053 3.55-3.865 6.59.154 2.483 1.72 4.709 4.12 5.448 3.299 1.016 6.276.303 8.851-1.95 1.594-1.395 2.505-3.405 3.157-5.39.223-.679.376-1.38.576-2.128.47 1.746.364 3.437-.08 5.123-.453 1.72-1.401 3.14-2.752 4.34Zm2.192-9.117c-.48 1.314-1.42 2.278-2.512 3.122-1.013.782-2.144 1.256-3.434 1.287-.504.012-1.03-.016-1.514-.142-1.837-.476-2.489-2.323-1.392-3.872.578-.816 1.387-1.326 2.317-1.667a7.68 7.68 0 0 1 2.62-.457c1.32-.003 2.426.162 3.454.651.414.197.756.354.756.354s-.155.34-.295.724Z" />
        </LogoBase>
      )
    case 'square':
      return (
        <LogoBase
          role="img"
          width="32"
          height="33"
          viewBox="0 0 32 33"
          fill="none"
          aria-label={label}
          {...rest}
        >
          <rect
            y="0.966553"
            width="32"
            height="32"
            rx="6"
            fill={theme.colors['light-yellow-70'].toString()}
          />
          <path
            d="M19.9098 17.6589V17.4797C22.1417 17.4797 23.7219 15.9158 23.7219 13.6595C23.7219 10.4665 20.089 8.25092 15.8208 8.25092C12.302 8.25092 9.73615 9.44015 7.94415 11.0204L9.87462 16.266H10.1841C10.1841 16.266 12.3916 13.6025 15.2913 13.6025C17.5313 13.6025 19.5025 14.9791 19.2989 17.2109C18.0689 16.4045 16.1955 15.8995 14.5583 15.8995C10.8276 15.8995 7.85455 17.7159 7.85455 20.9904C7.85455 23.3607 9.87462 25.7148 13.1491 25.7148C17.401 25.7148 19.9424 22.0738 19.641 18.6364H19.8854C20.3089 21.3895 19.2337 23.597 17.8653 25.0794V25.3645H24.1455V25.0387C24.1455 20.7298 21.4086 18.7667 19.9098 17.6589ZM15.9185 21.2022C14.8108 21.2022 13.9636 20.4202 13.9636 19.3043C13.9636 18.0825 14.9492 17.3005 16.4154 17.3005C17.5639 17.3005 18.7532 17.8707 19.079 18.0662C18.7287 20.1107 17.458 21.2022 15.9185 21.2022Z"
            fill="black"
          />
        </LogoBase>
      )

    default:
      return null
  }
}
