import { useEffect, useRef, useMemo } from 'react'
import type { Channel } from '../constants'
import type { ValueOf } from '../types'

const windowId = Date.now()

function useCreateBroadcastChannelRef(name: ValueOf<typeof Channel>) {
  const authChannelRef = useRef<BroadcastChannel>(undefined)

  useEffect(() => {
    if ('BroadcastChannel' in window) {
      const channel = new BroadcastChannel(name)
      authChannelRef.current = channel
      return () => {
        channel.close()
        authChannelRef.current = undefined
      }
    }
  }, [name])

  return authChannelRef
}

export function useBroadcastChannel<Messages>(name: ValueOf<typeof Channel>) {
  const channelRef = useCreateBroadcastChannelRef(name)

  return useMemo(() => {
    const post = (message: Messages) => {
      channelRef.current?.postMessage({ id: windowId, message })
    }

    const subscribe = (
      listener: (message: Messages) => void,
      options?: AddEventListenerOptions
    ) => {
      const channel = channelRef.current
      const handleMessage = (event: MessageEvent<{ id: number; message: Messages }>) => {
        if (windowId === event.data.id) {
          // ignore events from same window
          return
        }

        listener(event.data.message)
      }

      channel?.addEventListener('message', handleMessage, options)
      return () => channel?.removeEventListener('message', handleMessage, options)
    }

    const once = (listener: (message: Messages) => void) =>
      subscribe(listener, { once: true })

    return {
      post,
      subscribe,
      once,
    }
  }, [channelRef])
}
