export const Sync = () => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.5 6.3A10 10 0 0 1 24 10h-3v3h8V5h-3v2.7A13 13 0 0 0 3 16h3a10 10 0 0 1 7.5-9.7Zm5 19.4A10 10 0 0 1 8 22h3v-3H3v8h3v-2.7A13 13 0 0 0 29 16h-3a10 10 0 0 1-7.5 9.7Z"
      fill="currentColor"
    />
  </svg>
)
