import type { CardScheme } from 'api/types/cards'

export function toCardScheme(input: unknown): CardScheme {
  switch (typeof input === 'string' && input.toLowerCase()) {
    case 'visa':
      return 'VISA'
    case 'mastercard':
      return 'MASTER_CARD'
    case 'american express':
      return 'AMEX'
    default:
      return 'UNKNOWN'
  }
}
