import { useTrackUIView } from 'domains/analytics/hooks'
import { getIntl } from 'domains/i18n/utils'
import { useMatchMedia } from 'kitchen/hooks/use-match-media'
import { generatePath, RouterLink } from 'kitchen/router'
import { FormattedMessage } from 'react-intl'
import { AnimatedSuccessIcon, Link, Status, Surface } from 'salad/components'
import * as Icons from 'salad/icons'
import type { StackProps, TextProps } from 'salad/primitives'
import { Grid, HStack, Text, VStack } from 'salad/primitives'
import { styled, theme } from 'salad/stitches'
import { media } from 'salad/tokens'
import {
  ApronFooter,
  TryApronPromo,
  PaymentSummaryCard,
  CaptureSignupButtons,
} from '../../../components'
import { Path } from '../../../constants'
import type { PaymentFlowValues } from '../types'

const LeftHalfBackground = styled('div', {
  gridRow: 1,
  gridColumn: 1,
  backgroundColor: theme.colors['page-background'],
})

const RightHalfBackground = styled('div', {
  gridRow: 1,
  gridColumn: 2,
  backgroundColor: theme.colors['white'],
})

const ContentWrapper = styled(VStack, {
  placeSelf: 'center',
  maxWidth: '512px',
  gap: theme.space[32],
})

const ReconcilePaymentBlock = (props: StackProps) => {
  const { formatMessage } = getIntl()

  return (
    <ContentWrapper {...props}>
      <Surface p={32}>
        <VStack gap={20}>
          <VStack gap={12}>
            <Text variant="headline-h3">
              <FormattedMessage
                id="get-paid.capture.signup-popover.title"
                defaultMessage="Reconcile this payment"
              />
            </Text>
            <Text variant="paragraph-16" color="grey-60">
              <FormattedMessage
                id="get-paid.capture.signup-popover.description"
                defaultMessage="Continue to {brandName} to import this invoice to your accounting software."
                values={{
                  brandName: formatMessage({
                    id: 'no-translate.brand.apron',
                    defaultMessage: 'Apron',
                  }),
                }}
              />
            </Text>
          </VStack>
          <CaptureSignupButtons />
        </VStack>
      </Surface>
    </ContentWrapper>
  )
}

interface RepeatPaymentBlockProps extends TextProps {
  companyAliasName: string
}

const RepeatPaymentBlock = ({
  css,
  companyAliasName,
  ...rest
}: RepeatPaymentBlockProps) => {
  return (
    <Text
      variant="title-16"
      css={{
        color: theme.colors['dark-blue'],
        ...css,
      }}
      {...rest}
    >
      <HStack css={{ justifyContent: 'center', alignItems: 'center' }}>
        <Icons.S16.Rotate variant="right" />
        <Link
          as={RouterLink}
          to={generatePath(Path.COMPANY_ALIAS, {
            companyAliasName,
          })}
          standalone
        >
          Make another payment
        </Link>
      </HStack>
    </Text>
  )
}

interface SuccessResultStepProps {
  values: PaymentFlowValues
}

export const SuccessResultStep = ({ values }: SuccessResultStepProps) => {
  const isBp2 = useMatchMedia(media['bp2'])

  useTrackUIView({
    companyId: undefined,
    data: {
      target: 'get-paid-flow',
      step: 'success',
    },
  })

  return (
    // background halves stack
    <HStack
      css={{
        height: '100%',
        gridTemplateColumns: '1fr 1fr',
      }}
    >
      <LeftHalfBackground />
      <RightHalfBackground
        css={{
          display: 'none',
          '@bp2': {
            display: 'block',
          },
        }}
      />
      <Grid
        css={{
          gridRow: 1,
          gridColumn: '1 / -1',
          justifySelf: 'center',
          height: '100%',
          width: '100%',
          maxWidth: '1360px',
          minHeight: 'auto',
          paddingInline: theme.space[16],
          '& > *': {
            minHeight: 'auto',
          },

          rowGap: theme.space[24],
          gridTemplateAreas: `
          "."
          "payment"
          "promo"
          "reconcile"
          "repeat"
          "footer"
          `,
          '@bp2': {
            paddingInline: 0,
            gridTemplateColumns: '1fr 1fr',
            gridTemplateAreas: `
            ". ."
            "payment promo"
            "footer ."
            `,
          },
        }}
      >
        <ContentWrapper
          css={{
            gridArea: 'payment',
            '@bp2': {
              paddingInline: theme.space[32],
            },
          }}
        >
          <Status.Root>
            <Status.Icon>
              <AnimatedSuccessIcon />
            </Status.Icon>
            <Status.Title>
              <FormattedMessage
                id="get-paid.payment.success.header"
                defaultMessage="Ding! It’s done"
              />
            </Status.Title>
          </Status.Root>
          <PaymentSummaryCard
            instrumentType={values.instrumentType}
            payrun={values.payrun}
            beneficiaryCompany={values.beneficiaryCompany}
          />
          {isBp2 && (
            <RepeatPaymentBlock companyAliasName={values.beneficiaryCompany.alias} />
          )}
        </ContentWrapper>

        <ContentWrapper
          p={32}
          css={{
            gridArea: 'promo',
            background: theme.colors.white,
            borderRadius: theme.radii[16],
          }}
        >
          <TryApronPromo />
        </ContentWrapper>

        {!isBp2 && <ReconcilePaymentBlock css={{ gridArea: 'reconcile' }} />}

        {!isBp2 && (
          <RepeatPaymentBlock
            companyAliasName={values.beneficiaryCompany.alias}
            css={{ gridArea: 'repeat', paddingBlock: theme.space[24] }}
          />
        )}

        <ApronFooter
          css={{
            gridArea: 'footer',
          }}
        />
      </Grid>
    </HStack>
  )
}
