import { getIntl } from 'domains/i18n/utils'
import { FormattedMessage } from 'react-intl'
import { Surface, Popover, IconButton } from 'salad/components'
import * as Icons from 'salad/icons'
import { VStack, Text, Flex } from 'salad/primitives'
import { keyframes } from 'salad/stitches'
import { CaptureSignupButtons } from '../capture-signup-buttons'

const slideUp = keyframes({
  from: {
    opacity: '0',
    transform: 'translateY(20px) scale(0.95)',
  },
  to: {
    opacity: '1',
    transform: 'translateY(0) scale(1)',
  },
})

const slideDown = keyframes({
  from: {
    opacity: '1',
    transform: 'translateY(0) scale(1)',
  },
  to: {
    opacity: '0',
    transform: 'translateY(20px) scale(0.95)',
  },
})

const CaptureSignupPopoverContent = () => {
  const { formatMessage } = getIntl()
  return (
    <Popover.Content
      side="top"
      sideOffset={0}
      css={{
        width: '448px',
        maxWidth: '100%',
        '&[data-state="open"]': {
          animation: `${slideUp} 0.15s ease-out`,
        },
        '&[data-state="closed"]': {
          animation: `${slideDown} 0.15s ease-out`,
        },
      }}
    >
      <Surface variant="toolbar" p={32}>
        <VStack gap={24}>
          <VStack gap={12}>
            <Flex align="center" justify="between">
              <Text variant="headline-h3">
                <FormattedMessage
                  id="get-paid.capture.signup-popover.title"
                  defaultMessage="Reconcile this payment"
                />
              </Text>
              <Popover.Close asChild>
                <IconButton size={24}>
                  <Icons.S24.Cross />
                </IconButton>
              </Popover.Close>
            </Flex>
            <Text variant="paragraph-16" color="black-alpha-60">
              <FormattedMessage
                id="get-paid.capture.signup-popover.description"
                defaultMessage="Continue to {brandName} to import this invoice to your accounting software."
                values={{
                  brandName: formatMessage({
                    id: 'no-translate.brand.apron',
                    defaultMessage: 'Apron',
                  }),
                }}
              />
            </Text>
          </VStack>
          <CaptureSignupButtons />
        </VStack>
      </Surface>
    </Popover.Content>
  )
}

const CaptureSignupPopoverRoot = Popover.Root
const CaptureSignupPopoverAnchor = Popover.Anchor
const CaptureSignupPopoverTrigger = Popover.Trigger

export {
  CaptureSignupPopoverRoot as Root,
  CaptureSignupPopoverContent as Content,
  CaptureSignupPopoverAnchor as Anchor,
  CaptureSignupPopoverTrigger as Trigger,
}
