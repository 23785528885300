import { useBeneficiaryCompany } from 'domains/company/queries'
import { getIntl } from 'domains/i18n/utils'
import { useCreateDraftGetPaidPayrun } from 'domains/payrun/queries'
import { StorageKey } from 'kitchen/constants'
import {
  generatePath,
  Route,
  Routes,
  useNavigate,
  useParams,
  useQueryState,
} from 'kitchen/router'
import { ErrorCode } from 'kitchen/types'
import { ExtendedResponseError } from 'kitchen/utils/error'
import { assert } from 'kitchen/utils/helpers'
import type { ReactNode } from 'react'
import { Suspense } from 'react'
import { FormattedMessage } from 'react-intl'
import { Toast } from 'salad/components'
import { StatusLayout } from 'salad/layouts'
import { match } from 'ts-pattern'
import {
  CompanyNotFoundErrorStatus,
  LoadingStatus,
  SomethingWentWrongErrorStatus,
} from '../../components'
import { Match, Path } from '../../constants'
import { PaymentFlow } from './payment-flow'
import { EntryStep } from './steps'

export const PaymentFlowPage = () => {
  const [email] = useQueryState('email')

  const toast = Toast.useContext()
  const intl = getIntl()
  const navigate = useNavigate()

  const { companyAliasName } = useParams<{ companyAliasName: string }>()
  assert(companyAliasName)

  const beneficiaryCompany = useBeneficiaryCompany({ companyAliasName })

  const createDraftPayrun = useCreateDraftGetPaidPayrun({
    onSuccess: ({ id }) => {
      navigate({
        pathname: generatePath(Path.COMPANY_ALIAS_PAYMENT, {
          companyAliasName,
          payrunId: id,
        }),
      })
    },
    onError: (_, variables) =>
      toast.show(
        <Toast.Root>
          <Toast.Title>
            <FormattedMessage
              id="common.something-went-wrong"
              defaultMessage="Something went wrong"
            />
          </Toast.Title>
          <Toast.Action
            altText={intl.formatMessage({
              id: 'common.try-again',
              defaultMessage: 'Try again',
            })}
            onClick={() => createDraftPayrun.mutate(variables)}
          >
            <FormattedMessage id="common.try-again" defaultMessage="Try again" />
          </Toast.Action>
        </Toast.Root>
      ),
  })

  return match(beneficiaryCompany)
    .returnType<ReactNode>()
    .with({ status: 'error' }, ({ error }) => {
      return (
        <StatusLayout.Root>
          <StatusLayout.Content>
            {error instanceof ExtendedResponseError &&
              match(error.meta.errorCode)
                .with(ErrorCode.NOT_FOUND, () => (
                  <CompanyNotFoundErrorStatus companyAliasName={companyAliasName} />
                ))
                .otherwise(() => <SomethingWentWrongErrorStatus error={error} />)}
          </StatusLayout.Content>
        </StatusLayout.Root>
      )
    })
    .with({ status: 'loading' }, () => <LoadingStatus />)
    .with({ status: 'success' }, (beneficiaryCompany) => {
      const companyWithAlias = {
        ...beneficiaryCompany.data,
        alias: companyAliasName,
      }
      return (
        <Routes>
          <Route
            index
            element={
              <EntryStep
                beneficiaryCompany={companyWithAlias}
                onSubmit={(values) => {
                  createDraftPayrun.mutate({
                    item: {
                      amount: values.amount,
                    },
                    reference: values.billReference,
                    companyId: values.beneficiaryCompany.id,
                  })
                }}
                submitting={createDraftPayrun.isLoading}
              />
            }
          />
          <Route
            path={Match.PAYMENT_PAYRUN_ID}
            element={
              <Suspense fallback={<LoadingStatus />}>
                <PaymentFlow
                  beneficiaryCompany={companyWithAlias}
                  email={
                    email ?? (localStorage.getItem(StorageKey.GET_PAID_PAYER_EMAIL) || '')
                  }
                />
              </Suspense>
            }
          />
        </Routes>
      )
    })
    .exhaustive()
}
