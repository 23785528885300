/* eslint @apron/sort-exports: error */

const PREFIX = '@apron'

export const ANONYMOUS_ID = `${PREFIX}/anonymousId` as const
export const AUTO_SKIP_KYB = `${PREFIX}/autoSkipKYB` as const
export const GET_PAID_PAYER_EMAIL = `${PREFIX}/get-paid-payer-email` as const
export const HAS_HIDDEN_WEBSITE_EXPENSE_PROMO_BANNER = `${PREFIX}/hasHiddenWebsiteExpensePromoBanner1`
export const HAS_SEEN_PAYROLL_ARTICLE = `${PREFIX}/hasSeenPayrollArticle` as const
export const INBOX_LAST_SEEN_DOCUMENT_REGISTERED_AT =
  `${PREFIX}/inboxLastSeenDocumentRegisteredAt` as const
export const INTERCOM_EXPANDED_MODE = `intercom.intercom-expanded-mode` as const
export const LOGGED_OUT_FROM_LOCATION = `${PREFIX}/loggedOutFromLocation` as const
export const SELECTED_CLIENTS = `${PREFIX}/selectedClients` as const

export const SESSION = `${PREFIX}/session` as const
