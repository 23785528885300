import { ButtonBase } from '../../primitives'
import { styled, theme } from '../../stitches'

export const NavigationButton = styled(ButtonBase, {
  display: 'grid',
  placeItems: 'center',
  borderRadius: theme.radii['round'],
  borderWidth: 2,
  borderStyle: 'solid',
  transitionDuration: '0.3s',
  transitionProperty: 'background-color, border-color, color',
  '@media print': {
    display: 'none',
  },
  variants: {
    size: {
      medium: {
        size: 48,
        borderColor: theme.colors['grey-20'],
        color: theme.colors['grey-20'],
        '&:hover': {
          borderColor: theme.colors['grey-15'],
          backgroundColor: theme.colors['grey-15'],
        },
        '&:active, &[aria-pressed]': {
          borderColor: theme.colors['grey-20'],
          backgroundColor: theme.colors['grey-20'],
        },
        '&:hover, &:active, &[aria-pressed]': {
          color: theme.colors['page-background'],
        },
      },
      small: {
        size: 28,
        color: theme.colors.black,
        borderColor: theme.colors.black,
        '&:hover': {
          color: theme.colors.white,
          borderColor: theme.colors.transparent,
          backgroundColor: theme.colors['black-alpha-80'],
        },
        '&:active, &[aria-pressed]': {
          color: theme.colors.white,
          borderColor: theme.colors.transparent,
          backgroundColor: theme.colors['black-alpha-70'],
        },
        '&:disabled, &[aria-disabled="true"]': {
          color: theme.colors['black-alpha-20'],
          borderColor: theme.colors['black-alpha-20'],
          backgroundColor: theme.colors.transparent,
        },
      },
    },
    variant: {
      overlay: {
        '&:hover, &:active, &[aria-pressed]': {
          color: theme.colors['dialog-overlay-icon'],
        },
      },
    },
  },
  defaultVariants: {
    size: 'medium',
  },
})
