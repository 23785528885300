import { VisuallyHidden } from 'radix-ui'
import { HStack, Text, type TextProps } from 'salad/primitives'
import type * as Tokens from 'salad/tokens'
import type { Typography } from 'salad/variants'
import { useMoneyFormat } from '../../hooks'
import type { Money } from '../../types'

export type PriceTagVariant =
  | 'large'
  | 'medium'
  | 'small'
  | Exclude<Typography, `price-tag-${string}`>

function getVariant(variant: PriceTagVariant, suffix: 'main' | 'support'): Typography {
  switch (variant) {
    case 'large':
      return `price-tag-large-${suffix}`
    case 'medium':
      return `price-tag-medium-${suffix}`
    case 'small':
      return `price-tag-small-${suffix}`
    default:
      return variant
  }
}

interface PriceTagProps extends Omit<TextProps, 'variant'> {
  prefix?: string
  value: Money | null
  variant: PriceTagVariant
  align?: 'start' | 'center' | 'end'
  color?: Tokens.Color
  strikeThrough?: boolean
}

export function PriceTag({
  prefix,
  align,
  value,
  variant,
  color,
  css,
  strikeThrough,
  ...rest
}: PriceTagProps) {
  const moneyFormat = useMoneyFormat()
  const integerVariant = getVariant(variant, 'main')
  const fractionVariant = getVariant(variant, 'support')

  if (value === null) {
    return (
      <Text variant={integerVariant} color={color} align={align} css={css} {...rest}>
        —
      </Text>
    )
  }

  if (integerVariant === fractionVariant) {
    return (
      <Text
        variant={integerVariant}
        color={color}
        align={align}
        strikeThrough={strikeThrough}
        css={css}
        {...rest}
      >
        {prefix}
        {moneyFormat.format(value)}
      </Text>
    )
  }

  const [main, fraction] = moneyFormat.formatToParts(value).reduce<[string, string]>(
    ([main, fraction], part) => {
      switch (part.type) {
        case 'fraction':
          return [main, fraction + part.value]
        case 'decimal':
          return [main, fraction]
        default:
          return [main + part.value, fraction]
      }
    },
    ['', '']
  )

  if (main === '' && fraction === '') {
    return null
  }

  return (
    <HStack
      as="span"
      css={{
        display: 'inline-grid',
        justifyContent: align,
        width: 'auto',
        textDecoration: strikeThrough ? 'line-through' : undefined,
        ...css,
      }}
      {...rest}
    >
      <Text variant={integerVariant} color={color} aria-hidden>
        {prefix}
        {main}
      </Text>
      <Text variant={fractionVariant} color={color} aria-hidden>
        {fraction}
      </Text>
      <VisuallyHidden.Root role="presentation" aria-labelledby={rest['aria-labelledby']}>
        {moneyFormat.format(value)}
      </VisuallyHidden.Root>
    </HStack>
  )
}
