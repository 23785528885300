import type { ApiRequest } from 'kitchen/types'
import { ApiError } from 'kitchen/utils/error'
import { fetchFile } from 'kitchen/utils/fetch'
import type {
  ApprovePayrunPayload,
  CreateDraftPayrunPayload,
  CreateDraftPayrollPayload,
  CreateDraftManualPaymentPayload,
  DeclinePayrunPayload,
  AuthorizeBankPaymentPayload,
  Payrun,
  PayrunContributors,
  PayrunEstimatesPayload,
  PayrunEstimatesResponse,
  PayrunPayload,
  PayrunPendingCountPayload,
  PayrunResponse,
  TopupOptionsPayload,
  UpdatePayrunContributorsPayload,
  UpdatePayrunIsGroupedPayload,
  UpdatePayrunPayload,
  PendingPayrunsStats,
  PendingPayrunsPayload,
  PendingPayrunsResponse,
  PendingPayrunsSuggestions,
  TopupOptionsResponse,
  PayrunAuditLogPayload,
  PayrunAuditLog,
  CreateBankPaymentResponse,
  CreateBankPaymentPayload,
  StartBankPaymentPayload,
  DeclineBankPaymentPayload,
  GetPaidPayrun,
  CreateDraftGetPaidPayrunPayload,
  GetPaidPayrunPayload,
  DownloadBacsPayload,
  ExportBankPaymentPayload,
  BacsFile,
  UpdateGetPaidPayrunAuthorPayload,
  UpdateGetPaidPayrunPayload,
} from './types'

export const getPayrun: ApiRequest<PayrunPayload, Payrun> = (
  fetch,
  { payrunId, anyState }
) =>
  payrunId
    ? fetch
        .get(`/payruns/${payrunId}`, {
          params: anyState ? { anystate: anyState } : undefined,
        })
        .json<Payrun>()
    : Promise.reject(new ApiError('payrunId is undefined'))

export const getGetPaidPayrun: ApiRequest<GetPaidPayrunPayload, GetPaidPayrun> = (
  fetch,
  { payrunId }
) => fetch.get(`/getpaid/payruns/${payrunId}`).json<GetPaidPayrun>()

export const createDraftPayrun: ApiRequest<CreateDraftPayrunPayload, Payrun> = (
  fetch,
  payload
) => fetch.post('/payruns/draft', { json: payload }).json<Payrun>()

export const createDraftGetPaidPayrun: ApiRequest<
  CreateDraftGetPaidPayrunPayload,
  GetPaidPayrun
> = (fetch, payload) => {
  return fetch.post('/getpaid/payruns/draft', { json: payload }).json()
}

export const createDraftPayroll: ApiRequest<CreateDraftPayrollPayload, Payrun> = (
  fetch,
  { companyId, file }
) => {
  const formData = new FormData()
  formData.append('payroll', file)

  return fetch
    .post('/payroll/draft', { body: formData, params: { companyId } })
    .json<Payrun>()
}

export const createDraftManualPayment: ApiRequest<
  CreateDraftManualPaymentPayload,
  Payrun
> = (fetch, payload) =>
  fetch.post('/payruns/expense/draft', { json: payload }).json<Payrun>()

export const updatePayrun: ApiRequest<UpdatePayrunPayload, Payrun> = (
  fetch,
  { payrunId, ...json }
) =>
  fetch
    .patch(`/payruns/${payrunId}`, { json })
    .json<PayrunResponse>()
    .then((data) => data.payrun)

export const updatePayrunContributors: ApiRequest<
  UpdatePayrunContributorsPayload,
  void
> = (fetch, { payrunId, ...json }) =>
  fetch.patch(`/payruns/${payrunId}/contributors`, { json }).void()

export const updateGetPaidPayrunAuthor: ApiRequest<
  UpdateGetPaidPayrunAuthorPayload,
  void
> = (fetch, { payrunId, ...json }) =>
  fetch.post(`/getpaid/payruns/${payrunId}/author`, { json }).void()

export const updateGetPaidPayrun: ApiRequest<
  UpdateGetPaidPayrunPayload,
  GetPaidPayrun
> = (fetch, { payrunId, ...json }) =>
  fetch.patch(`/getpaid/payruns/${payrunId}`, { json }).json()

export const approvePayrun: ApiRequest<ApprovePayrunPayload, PayrunContributors> = (
  fetch,
  { payrunId, ...json }
) =>
  fetch
    .post(`/payruns/${payrunId}/contributors/approve`, { json })
    .json<PayrunContributors>()

export const declinePayrun: ApiRequest<DeclinePayrunPayload, void> = (
  fetch,
  { payrunId }
) => fetch.post(`/payruns/${payrunId}/decline`).void()

export const getPayrunTopupOptions: ApiRequest<
  TopupOptionsPayload,
  TopupOptionsResponse
> = (fetch, { accountId, total }, signal) =>
  fetch
    .get('/topup-options', { params: { accountId, ...total }, signal })
    .json<TopupOptionsResponse>()

export const getPayrunEstimates: ApiRequest<
  PayrunEstimatesPayload,
  PayrunEstimatesResponse
> = (fetch, payload, signal) =>
  fetch
    .post('/payruns/estimates', { json: payload, signal })
    .json<PayrunEstimatesResponse>()

export const updatePayrunIsGrouped: ApiRequest<UpdatePayrunIsGroupedPayload, void> = (
  fetch,
  { payrunId, isGrouped }
) =>
  fetch.patch(`/payruns/${payrunId}/update-is-grouped`, { params: { isGrouped } }).void()

export const getPayrunPendingCount: ApiRequest<PayrunPendingCountPayload, number> = (
  fetch,
  payload,
  signal
) => fetch.get('/payruns/pending-count', { params: payload, signal }).json<number>()

export const getPendingPayruns: ApiRequest<
  PendingPayrunsPayload,
  PendingPayrunsResponse
> = (fetch, params, signal) =>
  fetch.get('/payruns/pending', { params, signal }).json<PendingPayrunsResponse>()

export const getPendingPayrunsStats: ApiRequest<void, PendingPayrunsStats> = (
  fetch,
  signal
) => fetch.get('/payruns/pending/stats', { signal }).json<PendingPayrunsStats>()

export const getPendingPayrunsSuggestions: ApiRequest<void, PendingPayrunsSuggestions> = (
  fetch,
  signal
) =>
  fetch.get('/payruns/pending/suggestions', { signal }).json<PendingPayrunsSuggestions>()

export const getPayrunAuditLog: ApiRequest<PayrunAuditLogPayload, PayrunAuditLog> = (
  fetch,
  { payrunId }
) =>
  payrunId
    ? fetch.get(`/payruns/${payrunId}/audit-log`).json<PayrunAuditLog>()
    : Promise.reject(new ApiError('payrunId is undefined'))

export const createBankPayment: ApiRequest<
  CreateBankPaymentPayload,
  CreateBankPaymentResponse
> = (fetch, { payrunId }) =>
  fetch.post(`/payruns/${payrunId}/bank-payment`).json<CreateBankPaymentResponse>()

export const startBankPayment: ApiRequest<StartBankPaymentPayload, void> = (
  fetch,
  { payrunId, bankPaymentId: bankPaymentId }
) => fetch.post(`/payruns/${payrunId}/bank-payment/${bankPaymentId}/start`).void()

export const authorizeBankPayment: ApiRequest<AuthorizeBankPaymentPayload, void> = (
  fetch,
  { payrunId, bankPaymentId }
) => fetch.post(`/payruns/${payrunId}/bank-payment/${bankPaymentId}/authorize`).void()

export const exportBankPayment: ApiRequest<ExportBankPaymentPayload, void> = (
  fetch,
  { payrunId, bankPaymentId }
) => fetch.post(`/payruns/${payrunId}/bank-payment/${bankPaymentId}/export`).void()

export const declineBankPayment: ApiRequest<DeclineBankPaymentPayload, void> = (
  fetch,
  { payrunId, bankPaymentId }
) => fetch.post(`/payruns/${payrunId}/bank-payment/${bankPaymentId}/decline`).void()

export const downloadBacs: ApiRequest<DownloadBacsPayload, BacsFile> = (
  fetch,
  { payrunId, bankAccountId }
) =>
  fetch
    .post(`/payruns/${payrunId}/generate-bacs`, { json: { bankAccountId } })
    .then((response) => fetchFile(response, 'bacs.txt'))
