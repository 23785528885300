import { match, P } from 'ts-pattern'
import { getIntl } from '../../i18n/utils'
import type { InstrumentType } from '../types'

export function getInstrumentTypeLabel(instrumentType: InstrumentType | null) {
  const { formatMessage } = getIntl()

  return match(instrumentType)
    .with(null, () => null)
    .with(P.union('PLAID', 'BANK_ACCOUNT'), () =>
      formatMessage({
        id: 'instrument.instrument-type.plaid',
        defaultMessage: 'Bank',
      })
    )
    .with('CARD', () =>
      formatMessage({
        id: 'instrument.instrument-type.card',
        defaultMessage: 'Card',
      })
    )
    .with('WALLET', () =>
      formatMessage({
        id: 'instrument.instrument-type.wallet',
        defaultMessage: 'Wallet',
      })
    )
    .exhaustive()
}
