import { ToggleGroup } from 'radix-ui'
import { styled, theme } from '../../stitches'
import { typography } from '../../variants'
import type { SeparatorProps } from '../separator'
import { Separator } from '../separator'

const SegmentControlBase = styled(ToggleGroup.Root, {
  display: 'flex',
  gap: theme.space[4],
  padding: theme.space[2],
  backgroundColor: theme.colors['grey-10'],
  // horizontal orientation (default)
  flexDirection: 'row',
  borderRadius: theme.radii['round'],
  '&[data-orientation="vertical"]': {
    flexDirection: 'column',
    borderRadius: 24,
  },
  overflow: 'auto',
  scrollbarWidth: 'thin',

  variants: {
    size: {
      medium: {
        '[data-radix-collection-item]': {
          ...typography['title-16'],
          height: 44,
          paddingInline: theme.space[24],
        },
      },
      small: {
        '[data-radix-collection-item]': {
          ...typography['title-16'],
          height: 36,
          paddingInline: theme.space[24],
        },
      },
      'extra-small': {
        '[data-radix-collection-item]': {
          ...typography['title-14'],
          height: 28,
          paddingInline: theme.space[12],
        },
      },
    },
  },
  defaultVariants: {
    size: 'medium',
  },
})

export interface SegmentControlProps<Value extends string>
  extends Omit<React.ComponentProps<typeof SegmentControlBase>, 'type'> {
  defaultValue?: Value
  value?: Value
  onValueChange?: (value: Value) => void
}

function SegmentControl<Value extends string>({
  onValueChange,
  ...rest
}: SegmentControlProps<Value>) {
  return (
    <SegmentControlBase
      type="single"
      onValueChange={(value: Value | '') => {
        if (value) {
          onValueChange?.(value)
        }
      }}
      {...rest}
    />
  )
}

const SegmentControlItemBase = styled(ToggleGroup.Item, {
  flex: '1 1 auto',
  minWidth: 0,
  whiteSpace: 'nowrap',
  textAlign: 'center',
  color: theme.colors['grey-60'],
  borderRadius: theme.radii['round'],
  transition: 'background-color 0.3s',
  '&[data-state="on"]': {
    backgroundColor: theme.colors['white'],
    color: theme.colors['black'],
  },
  '&:hover:not([data-state="on"], :disabled)': {
    backgroundColor: theme.colors['white-alpha-50'],
  },
  cursor: 'pointer',
  '&:disabled': {
    opacity: '0.3',
    cursor: 'auto',
  },
})

export interface SegmentControlItemProps<Value extends string>
  extends React.ComponentProps<typeof SegmentControlItemBase> {
  value: Value
}

function SegmentControlItem<Value extends string>(props: SegmentControlItemProps<Value>) {
  return <SegmentControlItemBase {...props} />
}

function SegmentControlSeparator(props: SeparatorProps) {
  return (
    <Separator size={1} orientation="vertical" color="grey-40" mx={4} py={8} {...props} />
  )
}

export {
  SegmentControl as Root,
  SegmentControlItem as Item,
  SegmentControlSeparator as Separator,
}
