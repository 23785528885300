import type { BeneficiaryCompany } from 'domains/company/types'
import { getDateFormat, getIntl } from 'domains/i18n/utils'
import type { GetPaidInstrumentType } from 'domains/instrument/types'
import { getInstrumentTypeLabel } from 'domains/instrument/utils'
import { PriceTag } from 'domains/money/components'
import { useMoneyFormat } from 'domains/money/hooks'
import { subtract } from 'domains/money/utils'
import type { GetPaidPayrun } from 'domains/payrun/types'
import { useMatchMedia } from 'kitchen/hooks/use-match-media'
import { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import {
  DataGroup,
  Separator,
  Link,
  AnimateTransition,
  AnimateHeight,
  Button,
} from 'salad/components'
import { Flex, VStack, Text, HStack } from 'salad/primitives'
import { theme } from 'salad/stitches'
import { media } from 'salad/tokens'
import { match } from 'ts-pattern'
import { CaptureSignupPopover } from '../capture-signup-popover'
import { PaymentSummaryCardContainer } from './payment-summary-card-container'

interface PaymentSummaryCardProps {
  payrun: GetPaidPayrun
  instrumentType: GetPaidInstrumentType | null // TODO NET-77 make it always have instrument details
  beneficiaryCompany: BeneficiaryCompany
}

const PaymentDetails = ({
  payrun,
  instrumentType,
  beneficiaryCompany,
}: PaymentSummaryCardProps) => {
  const moneyFormat = useMoneyFormat()
  const dateFormat = getDateFormat()
  const paymentMethodLabel = getInstrumentTypeLabel(instrumentType)

  return (
    <VStack gap={24}>
      <VStack
        gap={24}
        css={{
          '[data-group]': {
            gridAutoFlow: 'column',
            justifyContent: 'space-between',
          },
        }}
      >
        <DataGroup.Root>
          <DataGroup.Label>
            <FormattedMessage
              id="get-paid.payment.summary.label.reference"
              defaultMessage="Reference"
            />
          </DataGroup.Label>
          <DataGroup.Value>{payrun.reference}</DataGroup.Value>
        </DataGroup.Root>
        <DataGroup.Root>
          <DataGroup.Label>
            <FormattedMessage
              id="get-paid.payment.summary.label.beneficiary"
              defaultMessage="Paid to"
            />
          </DataGroup.Label>
          <DataGroup.Value>{beneficiaryCompany.legalName}</DataGroup.Value>
        </DataGroup.Root>
        <DataGroup.Root>
          <DataGroup.Label>
            <FormattedMessage
              id="get-paid.payment.summary.label.amount"
              defaultMessage="Amount"
            />
          </DataGroup.Label>
          <DataGroup.Value>
            {moneyFormat.format(subtract(payrun.amount, payrun.fee))}
          </DataGroup.Value>
        </DataGroup.Root>

        {payrun.fee.amount > 0 && (
          <DataGroup.Root>
            <DataGroup.Label>
              {match(instrumentType)
                .with('PLAID', () => (
                  <FormattedMessage
                    id="get-paid.payment.summary.label.processing-fee.bank"
                    defaultMessage="Bank payment processing fee"
                  />
                ))
                .with('CARD', () => (
                  <FormattedMessage
                    id="get-paid.payment.summary.label.processing-fee.card"
                    defaultMessage="Card payment processing fee"
                  />
                ))
                .with(null, () => (
                  <FormattedMessage
                    id="get-paid.payment.summary.label.processing-fee.unknown-instrument"
                    defaultMessage="Processing fee"
                  />
                ))
                .exhaustive()}
            </DataGroup.Label>
            <DataGroup.Value>{moneyFormat.format(payrun.fee)}</DataGroup.Value>
          </DataGroup.Root>
        )}
      </VStack>
      <Separator variant="dotted" />

      <DataGroup.Root align="end">
        <DataGroup.Label>
          <FormattedMessage
            id="get-paid.payment.summary.label.total"
            defaultMessage="Total amount"
          />
        </DataGroup.Label>
        <DataGroup.Value>
          <PriceTag variant="medium" value={payrun.amount} />
        </DataGroup.Value>
      </DataGroup.Root>

      <Separator variant="dotted" />

      <Flex justify="between">
        {instrumentType && (
          <DataGroup.Root>
            <DataGroup.Label>
              <FormattedMessage
                id="get-paid.payment.summary.label.payment-method"
                defaultMessage="Payment method"
              />
            </DataGroup.Label>
            {paymentMethodLabel}
          </DataGroup.Root>
        )}
        {payrun.paidAt && (
          <Flex align="end">
            <Text variant="paragraph-16">
              <FormattedMessage
                id="get-paid.payment.summary.value.payment-date"
                defaultMessage="Paid on {date}"
                values={{ date: dateFormat.format(payrun.paidAt) }}
              />
            </Text>
          </Flex>
        )}
      </Flex>
    </VStack>
  )
}

const CollapsiblePaymentSummaryCard = ({
  payrun,
  instrumentType,
  beneficiaryCompany,
}: PaymentSummaryCardProps) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <PaymentSummaryCardContainer>
      <VStack gap={24}>
        <AnimateHeight>
          <AnimateTransition
            animationKey={isOpen.toString()}
            transitionOut={{ duration: 0.1, ease: 'easeOut' }}
            transitionIn={{ duration: 0.3, ease: 'easeIn' }}
          >
            {isOpen ? (
              <Text variant="headline-h2">
                <FormattedMessage
                  id="get-paid.payment.summary.title"
                  defaultMessage="Payment"
                />
              </Text>
            ) : (
              <HStack
                css={{ justifyContent: 'space-between', alignItems: 'flex-start' }}
                gap={24}
              >
                <DataGroup.Root>
                  <DataGroup.Label>
                    <FormattedMessage
                      id="get-paid.payment.summary.label.beneficiary"
                      defaultMessage="Paid to"
                    />
                  </DataGroup.Label>
                  <DataGroup.Value>{beneficiaryCompany.legalName}</DataGroup.Value>
                </DataGroup.Root>
                <DataGroup.Root align="end">
                  <DataGroup.Label>
                    <FormattedMessage
                      id="get-paid.payment.summary.label.total"
                      defaultMessage="Total amount"
                    />
                  </DataGroup.Label>
                  <DataGroup.Value>
                    <PriceTag variant="medium" value={payrun.amount} />
                  </DataGroup.Value>
                </DataGroup.Root>
              </HStack>
            )}
          </AnimateTransition>
        </AnimateHeight>

        <Separator variant="dotted" />
        <AnimateHeight>
          <AnimateTransition
            animationKey={isOpen.toString()}
            transitionOut={{ duration: 0.1, ease: 'easeOut' }}
            transitionIn={{ duration: 0.3, ease: 'easeIn' }}
          >
            {isOpen && (
              <VStack pb={24}>
                <PaymentDetails
                  payrun={payrun}
                  instrumentType={instrumentType}
                  beneficiaryCompany={beneficiaryCompany}
                />
              </VStack>
            )}
          </AnimateTransition>
        </AnimateHeight>
      </VStack>
      <Text variant="title-16">
        <Link as="button" onClick={() => setIsOpen((prev) => !prev)}>
          {isOpen ? (
            <FormattedMessage
              id="get-paid.payment.summary.toggle-hide"
              defaultMessage="Hide full receipt"
            />
          ) : (
            <FormattedMessage
              id="get-paid.payment.summary.toggle-open"
              defaultMessage="View full receipt"
            />
          )}
        </Link>
      </Text>
    </PaymentSummaryCardContainer>
  )
}

export const PaymentSummaryCard = ({
  payrun,
  instrumentType,
  beneficiaryCompany,
}: PaymentSummaryCardProps) => {
  const isBp2 = useMatchMedia(media['bp2'])
  const [isCaptureDialogOpen, setIsCaptureDialogOpen] = useState(false)
  const { formatMessage } = getIntl()

  if (isBp2) {
    return (
      <CaptureSignupPopover.Root
        open={isCaptureDialogOpen}
        onOpenChange={setIsCaptureDialogOpen}
      >
        <VStack>
          <PaymentSummaryCardContainer
            variant={isCaptureDialogOpen ? 'blurred' : 'default'}
          >
            <VStack gap={24}>
              <Text variant="headline-h2">
                <FormattedMessage
                  id="get-paid.payment.summary.title"
                  defaultMessage="Payment"
                />
              </Text>
              <Separator variant="dotted" />
              <PaymentDetails
                payrun={payrun}
                instrumentType={instrumentType}
                beneficiaryCompany={beneficiaryCompany}
              />
            </VStack>
          </PaymentSummaryCardContainer>
          <CaptureSignupPopover.Trigger>
            <VStack
              gap={20}
              pt={40}
              pb={28}
              px={32}
              css={{
                height: 'fit-content',
                width: `calc(100% - ${theme.space[16]})`,
                marginBlockStart: `calc(-1 * ${theme.space[12]})`,
                marginInline: 'auto',
                backgroundColor: theme.colors['grey-10'],
                borderBottomLeftRadius: theme.radii[16],
                borderBottomRightRadius: theme.radii[16],
              }}
            >
              <Text variant="paragraph-16" color="black-alpha-60">
                <FormattedMessage
                  id="get-paid.payment.summary.capture.disclaimer"
                  defaultMessage="Continue to {brandName} to import this invoice to your accounting software."
                  values={{
                    brandName: formatMessage({
                      id: 'no-translate.brand.apron',
                      defaultMessage: 'Apron',
                    }),
                  }}
                />
              </Text>

              <Button.Root as="span" variant="common" size="medium">
                <Button.Content>
                  <FormattedMessage
                    id="get-paid.payment.summary.capture.import-button"
                    defaultMessage="Import to accounting"
                  />
                </Button.Content>
              </Button.Root>
            </VStack>
          </CaptureSignupPopover.Trigger>
          <CaptureSignupPopover.Anchor />
        </VStack>
        <CaptureSignupPopover.Content />
      </CaptureSignupPopover.Root>
    )
  }

  return (
    <CollapsiblePaymentSummaryCard
      payrun={payrun}
      instrumentType={instrumentType}
      beneficiaryCompany={beneficiaryCompany}
    />
  )
}
