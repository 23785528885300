import { ANONYMOUS_ID } from 'domains/analytics/constants'
import { getIntl } from 'domains/i18n/utils'
import { ExternalResource } from 'kitchen/constants'
import { FormattedMessage } from 'react-intl'
import { Button, Link, Status } from 'salad/components'
import { Text, VStack } from 'salad/primitives'
import { AppIllustration } from '../app-illustration'

interface CompanyNotFoundErrorStatusProps {
  companyAliasName: string
}

export const CompanyNotFoundErrorStatus = ({
  companyAliasName,
}: CompanyNotFoundErrorStatusProps) => {
  return (
    <Status.Root>
      <VStack gap={32} mb={32}>
        <VStack gap={48} css={{ justifyItems: 'center' }}>
          <AppIllustration companyAliasName={companyAliasName} />
          <VStack gap={12}>
            <Status.Title>
              <FormattedMessage
                id="get-paid.payment.entry.invalid-link-error.title"
                defaultMessage="This payment link is not{nbsp}ready yet"
                values={{ nbsp: <>&nbsp;</> }}
              />
            </Status.Title>
            <Status.Description>
              <FormattedMessage
                id="get-paid.payment.entry.invalid-link-error.description"
                defaultMessage="Claim this Get Paid link to start receiving payments from anyone, anywhere."
              />
            </Status.Description>
          </VStack>
        </VStack>
        <Button.Root
          css={{ maxWidth: '384px', margin: 'auto' }}
          variant="main"
          size="large"
          as="a"
          href={`${ExternalResource.MAIN_APP_AUTH}?rudderId=${ANONYMOUS_ID}`}
        >
          <Button.Content>
            <FormattedMessage id="common.get-started" defaultMessage="Get started" />
          </Button.Content>
        </Button.Root>
        <Text variant="button-medium">
          <Link
            href={`${ExternalResource.WEBSITE_GET_PAID}?rudderId=${ANONYMOUS_ID}&utm_source=get-paid-app-404`}
          >
            <FormattedMessage
              id="get-paid.payment.entry.invalid-link-error.learn-more"
              defaultMessage="Learn more about {featureName}"
              values={{
                featureName: getIntl().formatMessage({
                  id: 'no-translate.feature.get-paid',
                  defaultMessage: 'Get Paid',
                }),
              }}
            />
          </Link>
        </Text>
      </VStack>
    </Status.Root>
  )
}
