import { useMutation } from '@tanstack/react-query'
import { useSendAnalyticsEvent } from 'domains/analytics/queries'
import type { GetPaidInstrumentType } from 'domains/instrument/types'
import { usePayWithCard, usePayWithPlaid } from 'domains/payment/queries'
import type { PayrunId } from 'domains/payrun/types'
import type { UseMutationOptions, UUID } from 'kitchen/types'
import { assert } from 'kitchen/utils/helpers'
import { useEffect, useState } from 'react'
import { generatePath } from 'react-router'
import { CardChallenge } from 'salad/components'
import { match } from 'ts-pattern'
import { Path } from '../../constants'

interface PayPayloadBase {
  idempotencyKey: UUID | null
  payrunId: PayrunId
  instrumentType: GetPaidInstrumentType
}

interface PayWithCardPayload extends PayPayloadBase {
  instrumentType: 'CARD'
  token: string | undefined
  email: string
  cardholderName: string
}
interface PayWithPlaidPayload extends PayPayloadBase {
  instrumentType: 'PLAID'
}

type PayPayload = PayWithCardPayload | PayWithPlaidPayload

export function usePay(options: UseMutationOptions<PayPayload, void>) {
  const [element, setElement] = useState<React.ReactElement | null>(null)
  const payWithPlaid = usePayWithPlaid()
  const payWithCard = usePayWithCard()
  const { mutate: sendAnalyticsEvent } = useSendAnalyticsEvent()

  useEffect(() => {
    return () => {
      setElement(null)
    }
  }, [])

  const pay = useMutation<void, Error, PayPayload>(
    async ({ payrunId, idempotencyKey, ...data }) => {
      sendAnalyticsEvent({
        event: 'INITIATE_PAYMENT',
        subjectId: payrunId,
        data: {
          flowType: 'GET_PAID',
          instrumentType: data.instrumentType,
        },
      })

      return match(data)
        .with({ instrumentType: 'CARD' }, (data) => {
          assert(data.token, 'Token is not provided')
          return payWithCard.mutateAsync(
            {
              variant: 'get-paid',
              email: data.email,
              token: data.token,
              cardholderName: data.cardholderName,
              payrunId: payrunId,
              idempotencyKey,
              challenge: {
                successRedirectUri: new URL(
                  generatePath(Path.CARD_CHALLENGE_STATUS, { status: 'success' }),
                  window.location.origin
                ),
                failureRedirectUri: new URL(
                  generatePath(Path.CARD_CHALLENGE_STATUS, { status: 'failure' }),
                  window.location.origin
                ),
              },
              onChallenge: (url) => {
                setElement(<CardChallenge title="3DS challenge" url={url} />)
              },
              onChallengeComplete: () => {
                setElement(null)
              },
            },
            {
              onError: () => setElement(null),
            }
          )
        })
        .with({ instrumentType: 'PLAID' }, () => {
          return payWithPlaid.mutateAsync({
            variant: 'get-paid',
            payrunId: payrunId,
          })
        })
        .exhaustive()
    },
    options
  )

  return [pay, element] as const
}
