import { Tooltip as TooltipBase } from 'radix-ui'
import { forwardRef } from 'react'
import { styled, keyframes, theme } from 'salad/stitches'
import { Text } from '../../primitives'

const slideUpAndFade = keyframes({
  '0%': { opacity: 0, transform: 'translateY(2px)' },
  '100%': { opacity: 1, transform: 'translateY(0)' },
})

const slideRightAndFade = keyframes({
  '0%': { opacity: 0, transform: 'translateX(-2px)' },
  '100%': { opacity: 1, transform: 'translateX(0)' },
})

const slideDownAndFade = keyframes({
  '0%': { opacity: 0, transform: 'translateY(-2px)' },
  '100%': { opacity: 1, transform: 'translateY(0)' },
})

const slideLeftAndFade = keyframes({
  '0%': { opacity: 0, transform: 'translateX(2px)' },
  '100%': { opacity: 1, transform: 'translateX(0)' },
})

const fadeOut = keyframes({
  from: { opacity: 1 },
  to: { opacity: 0 },
})

const TooltipContentBase = styled(TooltipBase.Content, {
  '@media (prefers-reduced-motion: no-preference)': {
    animationDuration: '400ms',
    animationTimingFunction: 'cubic-bezier(0.16, 1, 0.3, 1)',
    animationFillMode: 'forwards',
    willChange: 'transform, opacity',
    '&[data-state="delayed-open"]': {
      '&[data-side="top"]': { animationName: slideUpAndFade },
      '&[data-side="right"]': { animationName: slideRightAndFade },
      '&[data-side="bottom"]': { animationName: slideDownAndFade },
      '&[data-side="left"]': { animationName: slideLeftAndFade },
    },
    '&[data-state="closed"]': {
      animation: `${fadeOut} 0.15s ease-out`,
    },
  },
  variants: {
    variant: {
      default: {
        boxShadow: theme.shadows['elevation-100'],
        backgroundColor: theme.colors['grey-60'],
        color: theme.colors.white,
        paddingY: theme.space[4],
        paddingX: theme.space[12],
        borderRadius: theme.radii[8],
      },
      unstyled: {},
    },
  },
})

const TooltipArrow = styled(TooltipBase.Arrow, {
  fill: theme.colors['grey-60'],
})

interface TooltipContentProps extends React.ComponentProps<typeof TooltipContentBase> {
  size?: number
}

const TooltipContent = forwardRef<HTMLDivElement, TooltipContentProps>(
  function TooltipContentRef(
    { variant = 'default', size, css, children, ...rest },
    forwardedRef
  ) {
    return (
      <TooltipBase.Portal>
        <TooltipContentBase
          ref={forwardedRef}
          variant={variant}
          sideOffset={variant === 'default' ? 6 : 0}
          css={{
            ...(size && { width: `min(${size}px, 100vw - 32px)` }),
            ...css,
          }}
          {...rest}
        >
          <Text variant="paragraph-16">{children}</Text>
        </TooltipContentBase>
      </TooltipBase.Portal>
    )
  }
)

const TooltipTrigger = forwardRef<HTMLButtonElement, TooltipBase.TooltipTriggerProps>(
  function TooltipTriggerRef(props, forwardedRef) {
    if (props.asChild) {
      return <TooltipBase.Trigger ref={forwardedRef} {...props} />
    }

    return <TooltipBase.Trigger type="button" ref={forwardedRef} {...props} />
  }
)

const Tooltip = (props: TooltipBase.TooltipProps) => {
  return <TooltipBase.Root delayDuration={100} {...props} />
}

export const { Provider } = TooltipBase
export {
  Tooltip as Root,
  TooltipContent as Content,
  TooltipArrow as Arrow,
  TooltipTrigger as Trigger,
}
export type { TooltipContentProps }
