import { AnimatePresence, type Transition, motion, useReducedMotion } from 'motion/react'
import { ZStack } from 'salad/primitives'

export const AnimateTransition = ({
  children,
  animationKey = children?.toString(),
  transitionIn = { duration: 0.3, ease: 'easeIn' },
  transitionOut = { duration: 0.3, ease: 'easeOut' },
}: {
  children: React.ReactNode
  animationKey?: string
  transitionIn?: Transition
  transitionOut?: Transition
}) => {
  const shouldReduceMotion = useReducedMotion()

  if (shouldReduceMotion) {
    return <>{children}</>
  }

  return (
    <ZStack as="span">
      <AnimatePresence>
        <motion.span
          key={animationKey}
          initial={{ opacity: 0 }}
          animate={{
            opacity: 1,
            transition: transitionIn,
          }}
          exit={{ opacity: 0, transition: transitionOut }}
        >
          {children}
        </motion.span>
      </AnimatePresence>
    </ZStack>
  )
}
