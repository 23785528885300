import { ANONYMOUS_ID } from 'domains/analytics/constants'
import { useSendInteraction } from 'domains/analytics/hooks'
import { VendorButton } from 'domains/connection/components'
import { ExternalResource } from 'kitchen/constants'
import { FormattedMessage } from 'react-intl'
import { Text, VStack } from 'salad/primitives'

export const CaptureSignupButtons = () => {
  const sendInteraction = useSendInteraction(undefined)

  const search = new URLSearchParams({
    autoSkipKYB: String(true),
    rudderId: ANONYMOUS_ID,
    utm_source: 'get-paid-capture-cta',
  }).toString()

  return (
    <VStack gap={16}>
      <VStack gap={12}>
        <VendorButton.Root
          variant="quickbooks-login"
          as="a"
          onClick={() => {
            sendInteraction({
              type: 'click',
              target: 'get-paid-capture-signup-button',
              variant: 'Quickbooks',
            })
          }}
          href={`${ExternalResource.MAIN_APP_AUTH}/init/quickbooks?${search}`}
        >
          <FormattedMessage
            id="get-paid.capture.sign-up-button.quickbooks"
            defaultMessage="Sign in with Intuit"
          />
        </VendorButton.Root>
        <VendorButton.Xero
          as="a"
          href={`${ExternalResource.MAIN_APP_AUTH}/init/xero?${search}`}
          onClick={() => {
            sendInteraction({
              type: 'click',
              target: 'get-paid-capture-signup-button',
              variant: 'Xero',
            })
          }}
        >
          <FormattedMessage
            id="get-paid.capture.sign-up-button.xero"
            defaultMessage="Continue with Xero"
          />
        </VendorButton.Xero>
      </VStack>
      <Text color="black-alpha-60" variant="paragraph-14" align="center">
        <FormattedMessage
          id="get-paid.capture.disclaimer"
          defaultMessage="Estimated 1–2 minutes to set up"
        />
      </Text>
    </VStack>
  )
}
