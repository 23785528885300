import type { ScaledMoney } from '../../money/types'
import { toFloat } from '../../money/utils'
import { getLocale } from './get-locale'

export interface MoneyFormatOptions
  extends Pick<
    Intl.NumberFormatOptions,
    'minimumFractionDigits' | 'maximumFractionDigits' | 'signDisplay'
  > {}

interface Formatter {
  format(input: ScaledMoney): string
  formatToParts(input: ScaledMoney): Intl.NumberFormatPart[]
  getValueString(input: ScaledMoney): string
}

export function getMoneyFormat({
  signDisplay = 'auto',
  maximumFractionDigits,
  minimumFractionDigits,
}: MoneyFormatOptions = {}): Formatter {
  const formatToParts = (input: ScaledMoney) => {
    const formatter = new Intl.NumberFormat(getLocale(), {
      style: 'currency',
      currencyDisplay: 'symbol',
      signDisplay,
      currency: input.currency,
      maximumFractionDigits,
      minimumFractionDigits,
    })

    return formatter.formatToParts(toFloat(input))
  }
  const getValueString = (input: ScaledMoney) => {
    return formatToParts(input).reduce((acc, part) => {
      switch (part.type) {
        case 'integer':
        case 'decimal':
        case 'fraction':
          return acc + part.value
        default:
          return acc
      }
    }, '')
  }

  const format = (input: ScaledMoney) =>
    formatToParts(input)
      .map((part) => part.value)
      .join('')

  return Object.assign(format, {
    format,
    formatToParts,
    getValueString,
  })
}
