import { ExternalResource } from '../constants'
import type { NonEmptyArray, Join, PrefixAll } from '../types'
import { nonWordChars, firstWordLetters } from './regex'

export const noop = () => void 0

export function assert<I>(input: I, message = 'Assertion failed'): asserts input {
  if (nonNullable(input)) {
    return
  }

  throw new Error(message)
}

export function ensure<I>(input: I | null | undefined, message?: string): I {
  assert(input, message)
  return input
}

export const nonNullable = <I>(input: I): input is NonNullable<I> =>
  input !== undefined && input !== null

export const isObject = (input: unknown): input is object =>
  input !== null && typeof input === 'object'

export const isNonEmpty = <T>(
  array: ReadonlyArray<T> | undefined
): array is NonEmptyArray<T> => array !== undefined && array.length > 0

export const isUnknownRecord = (input: unknown): input is Record<PropertyKey, unknown> =>
  isObject(input) && Object.keys(input).length >= 0

export const trim = (input: string) =>
  input.replace(/\s+/g, ' ').replace(/\r|\n/g, '').trim()

export const unique = <T>(input: Iterable<T>) => Array.from(new Set(input))

export const createDataSVG = (input: string) =>
  'data:image/svg+xml,' + encodeURIComponent(trim(input))

export function parseSafe<Value>(input: string | null): Value | undefined {
  try {
    return input === null ? undefined : JSON.parse(input)
  } catch (_) {
    return undefined
  }
}

/**
 * React doesn't allow modifying input value from outside.
 * Using the approved method from here: https://stackoverflow.com/questions/23892547/what-is-the-best-way-to-trigger-onchange-event-in-react-js
 */
export function triggerChange(
  input: HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement,
  value: string
) {
  for (const type of [HTMLInputElement, HTMLSelectElement, HTMLTextAreaElement]) {
    if (input instanceof type) {
      const descriptor = Object.getOwnPropertyDescriptor(type.prototype, 'value')

      if (descriptor?.set) {
        descriptor.set.call(input, value)
        input.dispatchEvent(new Event('input', { bubbles: true }))
      }

      return
    }
  }
}

export const range = (left: number, right: number) => {
  const length = Math.abs(right - left) + 1
  const ascending = left < right
  return Array(length)
    .fill(null)
    .map((_, index) => (ascending ? left + index : left - index))
}

export const cdn = <I extends string[]>(...input: I) =>
  input.map((item) => `${ExternalResource.CDN}${item}`).join(', ') as Join<
    PrefixAll<I, typeof ExternalResource.CDN>,
    ', '
  >

export const websiteCDN = <I extends string[]>(...input: I) =>
  input.map((item) => `${ExternalResource.WEBSITE_CDN}${item}`).join(', ') as Join<
    PrefixAll<I, typeof ExternalResource.WEBSITE_CDN>,
    ', '
  >

export function parseBoolean<S>(input?: S | `${boolean}` | boolean) {
  switch (input) {
    case 'true':
      return true
    case 'false':
      return false
    default:
      return input
  }
}

export const setRefs =
  <T>(...input: (React.MutableRefObject<T> | React.RefCallback<T> | null)[]) =>
  (value: T) => {
    input.forEach((ref) => {
      if (typeof ref === 'function') {
        ref(value)
      } else if (isObject(ref)) {
        ref.current = value
      }
    })
  }

export const abbreviate = (input: string, length = 2) => {
  const matches = input.replace(nonWordChars, ' ').match(firstWordLetters)
  const output = matches?.slice(0, length).join('')

  switch (output) {
    case 'ww':
      return 'w'
    default:
      return output
  }
}

export const hash = (input: string, arraySize: number, base = 69): number =>
  input.split('').reduce((acc, char) => (acc * base + char.charCodeAt(0)) % arraySize, 0)

export const formatName = (
  first: string | null | undefined,
  last: string | null | undefined
) =>
  [first, last]
    .filter((item) => typeof item === 'string' && item.trim().length > 0)
    .join(' ')

export const throws = (fn: () => void) => {
  try {
    fn()
    return false
  } catch (_) {
    return true
  }
}

// https://github.com/facebook/react/issues/11387
export const stopPropagation =
  <E extends React.SyntheticEvent>(callback: (event: E) => void) =>
  (event: E) => {
    event.stopPropagation()
    callback(event)
  }

/** @deprecated Use `createId` */
export const randomString = (length: number): string => {
  let result = ''
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  const charactersLength = characters.length
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
  }
  return result
}

export const createUUID = <T extends string>() =>
  typeof crypto !== 'undefined' && typeof crypto.randomUUID === 'function'
    ? toType<T>(crypto.randomUUID())
    : null

export const createId = <T extends string>(length = 36) =>
  ensure(toType<T>(createUUID() ?? randomString(length)))

export const isType = <T extends I, I = unknown>(input: I): input is T =>
  nonNullable(input)

export const toType = <T extends I, I = unknown>(input: I): T | null =>
  isType<T, I>(input) ? input : null

export const toTypedArray = <T extends I, I = unknown>(input: I[]): T[] =>
  input.filter((item) => isType<T, I>(item))

export const getNormalizedSearch = (input: string) =>
  input.trim().replace(/[.,£$€-]/g, '')

/**
 * `+` sign is replaced with empty space in `URLSearchParams` default parser
 * This helper restores the plus sign by replacing spaces with `+` signs
 */
export const normalizeEmail = (email: string) => {
  const trimmed = email.trim()

  if (!trimmed.includes('@')) {
    return trimmed
  }

  const [address, domain] = trimmed.split('@')
  return [address.replaceAll(' ', '+'), domain].join('@')
}

export const pick = <Data extends object, Key extends keyof Data>(
  key: Key
): ((data: Data) => Data[Key]) => {
  return (data: Data) => data[key]
}
