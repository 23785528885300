import type { DateTimeFormatter } from 'kitchen/types'
import { getDateTimeFormatter } from 'kitchen/utils/native-date'
import { getLocale } from './get-locale'

const OPTIONS = {
  short: {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
  },
  numeric: {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  },
  full: {
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  },
} as const

export type DateFormatStyle = keyof typeof OPTIONS

interface DateFormatOptions {
  timeZone?: string
  stripCurrentYear?: boolean
}

export function getDateFormat(
  style: DateFormatStyle = 'short',
  { timeZone, stripCurrentYear }: DateFormatOptions = {}
): DateTimeFormatter {
  return getDateTimeFormatter(
    getLocale(),
    { ...OPTIONS[style], timeZone },
    stripCurrentYear
  )
}
