import { FormattedMessage } from 'react-intl'
import { Status } from 'salad/components'
import * as Icons from 'salad/icons'

interface CardPaymentFailedErrorStatusProps {
  children?: React.ReactNode
}

export const CardPaymentFailedErrorStatus = ({
  children,
}: CardPaymentFailedErrorStatusProps) => {
  return (
    <Status.Root>
      <Status.Icon>
        <Icons.S64.Failure />
      </Status.Icon>
      <Status.Title>
        <FormattedMessage
          id="get-paid.payment.error.card-payment-failed.title"
          defaultMessage="Payment failed"
        />
      </Status.Title>
      <Status.Description>
        <FormattedMessage
          id="get-paid.payment.error.card-payment-failed.description"
          defaultMessage="You have not been charged. Please try again."
        />
      </Status.Description>
      {children}
    </Status.Root>
  )
}
