export const CDN = `https://cdn.getapron.com`
export const BLOG = 'https://blog.getapron.com'
export const WEBSITE_MAIN =
  process.env.BUILD_MODE === 'production'
    ? 'https://getapron.com'
    : 'https://dev.getapron.com'
export const MAIN_APP =
  process.env.BUILD_MODE === 'production'
    ? 'https://app.getapron.com'
    : 'https://app.dev.getapron.com'
export const MAIN_APP_AUTH = `${MAIN_APP}/auth` as const
export const WEBSITE_CAPTURE = `${WEBSITE_MAIN}/capture` as const
export const WEBSITE_GET_PAID = `${WEBSITE_MAIN}/getpaid` as const
export const WEBSITE_EXPENSES = `${WEBSITE_MAIN}/expenses` as const
export const WEBSITE_PRICING_ACCOUNTANTS = `${WEBSITE_MAIN}/pricing/accountants` as const
export const WEBSITE_CDN = `${CDN}/website` as const
export const COMPLAINTS_POLICY = `https://support.getapron.com/en/articles/6722902-how-to-file-a-complaint`
export const SUPPORT_EMAIL = `support@getapron.com`
export const QUICKBOOKS_EMAIL_VERIFICATION_URL = `https://accounts.intuit.com/app/account-manager/security`
export const FAQ = 'https://support.getapron.com/en/'
export const FAQ_REMITTANCE_ADVICE = `https://support.getapron.com/en/articles/6643613-how-do-i-send-remittance-advice-to-suppliers`
export const FAQ_MAKE_PAYROLL_FILE = `https://support.getapron.com/en/articles/7928901-how-to-make-a-payroll-file-for-uploading-into-apron`
export const FAQ_GROUP_PAYMENTS = `https://support.getapron.com/en/articles/7974434-how-to-group-payments-by-contact`
export const FAQ_SECURITY = `https://support.getapron.com/en/articles/8839661-how-does-apron-keep-money-secure`
export const FAQ_UPLOAD_LIMITS = `https://support.getapron.com/en/articles/9323429-what-files-can-i-upload-to-apron`
export const FAQ_PULL_BANK_FEED_FROM_QBO = `https://support.getapron.com/en/articles/9323615-how-do-i-pull-unreconciled-transactions-from-quickbooks`
export const FAQ_BANK_FEED_DISPLAY_SETTINGS =
  'https://support.getapron.com/en/articles/9616158-how-do-i-set-up-unreconciled-transactions-once-my-company-is-connected-to-xero'
export const ICB_REPORT = `https://www.bookkeepers.org.uk/out/277654/ICB-Apron-Accreditation-Review-2023.pdf`
export const BOOK_A_DEMO = 'https://share-eu1.hsforms.com/1luHxTTUoRwaEUic7dDPY4w2db27p'
export const APRON_LINKEDIN = 'https://www.linkedin.com/company/getapron'
export const APRON_INSTAGRAM = 'https://www.instagram.com/getapronapp/'
export const BLOG_POST_SECURITY =
  'https://blog.getapron.com/dont-become-a-fraud-statistic-see-how-apron-protects-you-6ebb8b614bb6'
export const BLOG_POST_SERIES_A =
  'https://blog.getapron.com/how-payment-platform-aprons-latest-12m-investment-will-change-the-game-for-smbs-and-their-dd78e87b2c12'
export const BLOG_POST_CLOUD_CFO =
  'https://blog.getapron.com/how-cloud-cfo-uses-apron-to-create-additional-revenue-2cb9cb6b5d41'
export const BLOG_POST_CAPTURE =
  'https://blog.getapron.com/call-your-clients-invoice-capture-just-got-easier-cb93edc6c6c2'
export const BLOG_POST_BUBALA =
  'https://blog.getapron.com/three-big-questions-with-marc-summers-511ec8e63e7f'
export const BLOG_POST_EMMA =
  'https://blog.getapron.com/thinking-out-loud-with-emma-james-990faabe1099'
export const BLOG_POST_MKS =
  'https://blog.getapron.com/how-simon-picks-a-payments-solution-is-a-skill-18-years-in-the-making-1d1b0b9f356c'
export const BLOG_POST_PRYSM =
  'https://blog.getapron.com/how-apron-helps-prysm-financial-manage-2-million-of-monthly-client-payments-with-less-risk-cbff13c07df6'
export const BLOG_POST_PITCH_THE_SWITCH =
  'https://blog.getapron.com/pitch-the-switch-how-to-onboard-clients-to-apron-capture-17c9c5b40a3c'
export const BLOG_POST_PAYING_WITH_CREDIT_CARD =
  'https://blog.getapron.com/3-hidden-benefits-of-paying-suppliers-with-your-credit-card-b7426aef1477'
export const BLOG_POST_CUT_FX_FEES =
  'https://blog.getapron.com/cut-fx-fees-for-your-clients-by-3-5x-and-reduce-complexity-fd8b1cfbb90d'
export const BLOG_AUTHENTICATOR_APP =
  'https://support.getapron.com/en/articles/9992922-what-is-an-authenticator-app'
export const CAPTURE_WEBINAR =
  'https://143283445.fs1.hubspotusercontent-eu1.net/hubfs/143283445/IC%20video.mp4?utm_campaign=pitchtheswitch&utm_source=landing'
export const CAPTURE_MASTERCLASS =
  'https://us06web.zoom.us/webinar/register/5617265858449/WN_yW94PeFrTq6d4BWo35eQjQ?utm_source=landing'
export const TRUSTPILOT_REVIEWS = 'https://www.trustpilot.com/review/getapron.com'
export const CURRENCY_CLOUD_TERMS = 'https://www.currencycloud.com/legal/terms/'
export const GET_PAID_DEMO_SESSION = 'https://calendly.com/alex-apron/apron-getpaid'
export const TERMS_AND_POLICIES = '/terms-and-policies'
export const TERMS_APRON_PAYMENTS =
  `${WEBSITE_MAIN}${TERMS_AND_POLICIES}/apron-payments` as const
export const TERMS_CURRENCY_CLOUD_AND_APRON_PAYMENTS =
  `${WEBSITE_MAIN}${TERMS_AND_POLICIES}/currency-cloud-and-apron-payments` as const
export const TERMS_PRIVACY_POLICY =
  `${WEBSITE_MAIN}${TERMS_AND_POLICIES}/privacy-policy` as const
export const GOOGLE_STORAGE_BASE = 'https://storage.googleapis.com/'

export const GOOGLE_PLAY_STORE = `https://play.google.com/store/apps/details?id=com.apron.prod`
export const APPLE_APP_STORE = `https://apps.apple.com/gb/app/apron/id6479799417`

export const EXPENSES_WAITLIST_FORM = 'https://form.typeform.com/to/Zs910CW0'
