import { useSendInteraction, useTrackUIView } from 'domains/analytics/hooks'
import { useHasFeatureFlag } from 'domains/feature-flag/queries'
import { getIntl } from 'domains/i18n/utils'
import { MoneyInput } from 'domains/money/components'
import { moneyFromFloat, toFloat } from 'domains/money/utils'
import { LOCAL_CURRENCY } from 'domains/payment/constants'
import { Controller, useForm, zodResolver } from 'kitchen/forms'
import { parseAsFloat, parseAsString, useQueryState } from 'kitchen/router'
import * as formats from 'kitchen/utils/formats'
import { useId, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import {
  InputGroup,
  Surface,
  Item,
  Avatar,
  Separator,
  Button,
  PayeeReferenceInput,
  PaymentNetworkIcon,
  SegmentControl,
  AnimateHeight,
  AnimateTransition,
  Logo,
} from 'salad/components'
import * as Icons from 'salad/icons'
import { StepLayout } from 'salad/layouts'
import { Box, Text, VStack, HStack } from 'salad/primitives'
import { theme } from 'salad/stitches'
import { match } from 'ts-pattern'
import { ApronFooter, CaptureSignupButtons } from '../../../components'
import { EntryStepSchema } from '../schemas'
import type { BeneficiaryCompany, EntryStepValues } from '../types'

type PageVariant = 'pay' | 'capture'

interface EntryStepProps {
  beneficiaryCompany: BeneficiaryCompany
  onSubmit: (values: EntryStepValues) => void
  submitting: boolean
}

export function EntryStep({ beneficiaryCompany, onSubmit, submitting }: EntryStepProps) {
  const [pageVariant, setPageVariant] = useState<PageVariant>('pay')
  const [amount, setUrlAmount] = useQueryState('amount', parseAsFloat.withDefault(0))
  const [billReference, setUrlBillReference] = useQueryState(
    'reference',
    parseAsString.withDefault('')
  )
  const [hasGetPaidCardPayment] = useHasFeatureFlag(
    beneficiaryCompany.id,
    ['GET_PAID_CARD_PAYMENT'],
    { suspense: false }
  )

  const { formatMessage } = getIntl()
  const formId = useId()
  const sendInteraction = useSendInteraction(undefined)

  const { handleSubmit, control } = useForm({
    defaultValues: {
      amount: moneyFromFloat({ float: amount, currency: LOCAL_CURRENCY }),
      billReference: formats.payeeReference(billReference, LOCAL_CURRENCY),
      beneficiaryCompany,
    },
    resolver: zodResolver(EntryStepSchema),
  })

  useTrackUIView({
    companyId: undefined,
    data: {
      target: 'get-paid-flow',
      step: 'entry',
    },
  })

  const onToggleView = (variant: PageVariant) => {
    setPageVariant(variant)
    sendInteraction({ type: 'toggle', target: 'get-paid-action-tab', variant })
  }

  const amountLabel = formatMessage({
    id: 'get-paid.payment.entry-step.input-label.amount',
    defaultMessage: 'Amount',
  })

  return (
    <StepLayout.Root size={512} css={{ gridTemplateRows: 'auto 1fr' }}>
      <VStack gap="32" as="form" id={formId} onSubmit={handleSubmit(onSubmit)}>
        <Surface variant="flat">
          <VStack>
            <Box p={24}>
              <Item.Root size="medium" css={{ minHeight: 62, pointerEvents: 'none' }}>
                <Item.Start>
                  <Avatar
                    variant="square"
                    name={beneficiaryCompany.legalName}
                    size="large"
                    aria-hidden
                  />
                </Item.Start>
                <Item.Content>
                  <VStack>
                    <FormattedMessage
                      id="get-paid.payment.entry-step.title"
                      defaultMessage="<h2>{companyName}</h2><p>is requesting a payment</p>"
                      values={{
                        companyName: beneficiaryCompany.legalName,
                        h2: (text) => <Text variant="headline-h2">{text}</Text>,
                        p: (text) => (
                          <Text variant="paragraph-16" color="black-alpha-60">
                            {text}
                          </Text>
                        ),
                      }}
                    />
                  </VStack>
                </Item.Content>
              </Item.Root>
            </Box>
            <Separator size={1} />
            <VStack p={32} gap={24}>
              <SegmentControl.Root<PageVariant>
                size={{ '@bp0': 'extra-small', '@bp1': 'medium' }}
                orientation="horizontal"
                value={pageVariant}
                onValueChange={onToggleView}
              >
                <SegmentControl.Item<PageVariant>
                  value="pay"
                  css={{ flexBasis: '50%', minWidth: 'max-content' }}
                >
                  <FormattedMessage
                    id="get-paid.payment.entry-step.segment-control.pay"
                    defaultMessage="Pay now"
                  />
                </SegmentControl.Item>
                <SegmentControl.Item<PageVariant>
                  value="capture"
                  css={{ minWidth: 'max-content' }}
                >
                  <FormattedMessage
                    id="get-paid.payment.entry-step.segment-control.capture"
                    defaultMessage="Import to accounting"
                  />
                </SegmentControl.Item>
              </SegmentControl.Root>
              <AnimateHeight>
                <AnimateTransition
                  animationKey={pageVariant}
                  transitionOut={{ duration: pageVariant === 'capture' ? 0 : 0.3 }}
                >
                  {match(pageVariant)
                    .with('pay', () => (
                      <VStack gap={24}>
                        <Controller
                          control={control}
                          name="amount"
                          render={({ field, fieldState: { error } }) => (
                            <InputGroup.Root>
                              <InputGroup.Label>{amountLabel}</InputGroup.Label>
                              <MoneyInput
                                aria-label={amountLabel}
                                aria-invalid={error !== undefined}
                                placeholder={field.value}
                                autoComplete="off"
                                value={field.value}
                                onValueChange={(value) => {
                                  setUrlAmount(toFloat(value))
                                  field.onChange(value)
                                }}
                              />
                              {error && (
                                <InputGroup.Message>{error.message}</InputGroup.Message>
                              )}
                            </InputGroup.Root>
                          )}
                        />

                        <Controller
                          control={control}
                          name="billReference"
                          render={({ field, fieldState: { error } }) => (
                            <InputGroup.Root>
                              <InputGroup.Label>
                                <FormattedMessage
                                  id="get-paid.payment.entry-step.input-label.reference"
                                  defaultMessage="Invoice reference"
                                />
                              </InputGroup.Label>
                              <PayeeReferenceInput
                                {...field}
                                onChange={(value) => {
                                  setUrlBillReference(value)
                                  field.onChange(value)
                                }}
                                currency={LOCAL_CURRENCY}
                                autoComplete="off"
                                aria-invalid={error !== undefined}
                              />
                              {error && (
                                <InputGroup.Message>{error.message}</InputGroup.Message>
                              )}
                            </InputGroup.Root>
                          )}
                        />
                      </VStack>
                    ))
                    .with('capture', () => (
                      <VStack
                        css={{
                          height: '200px',
                          alignContent: 'center',
                          '@bp2': { height: '280px' },
                        }}
                        gap={20}
                      >
                        <HStack
                          gap={8}
                          css={{ alignItems: 'center', placeContent: 'center' }}
                        >
                          <Avatar
                            color="celeste"
                            css={{ size: '56px', svg: { size: '60%' } }}
                          >
                            <Icons.S24.Person />
                          </Avatar>
                          <Text color="black-alpha-40">
                            <Icons.S32.Sync />
                          </Text>
                          <Avatar
                            css={{
                              size: '56px',
                              backgroundColor: theme.colors['light-yellow-70'],
                              color: theme.colors['black'],
                              svg: { size: '100%' },
                              overflow: 'hidden',
                            }}
                          >
                            <Logo variant="square" />
                          </Avatar>
                        </HStack>
                        <Text variant="paragraph-20" align="center">
                          <FormattedMessage
                            id="get-paid.payment.entry-step.capture.title"
                            defaultMessage="Continue to {brandName} to import this invoice to your accounting
                          software."
                            values={{
                              brandName: formatMessage({
                                id: 'no-translate.brand.apron',
                                defaultMessage: 'Apron',
                              }),
                            }}
                          />
                        </Text>
                      </VStack>
                    ))
                    .exhaustive()}
                </AnimateTransition>
              </AnimateHeight>
            </VStack>
            <Separator size={1} />

            <VStack p={32}>
              <AnimateHeight>
                <AnimateTransition
                  animationKey={pageVariant}
                  transitionOut={{ duration: pageVariant === 'capture' ? 0 : 0.3 }}
                >
                  {match(pageVariant)
                    .with('pay', () => (
                      <VStack gap={16}>
                        <Button.Root
                          form={formId}
                          variant="main"
                          size="large"
                          type="submit"
                          onClick={() => {
                            sendInteraction({
                              type: 'click',
                              target: 'get-paid-pay-invoice-button',
                            })
                          }}
                          loading={submitting}
                        >
                          <Button.Content>
                            <FormattedMessage
                              id="get-paid.payment.entry-step.submit"
                              defaultMessage="Pay invoice"
                            />
                          </Button.Content>
                        </Button.Root>
                        {hasGetPaidCardPayment ? (
                          <HStack
                            gap={12}
                            css={{ justifyContent: 'center', alignItems: 'center' }}
                          >
                            <PaymentNetworkIcon scheme="VISA" variant="color" />
                            <PaymentNetworkIcon scheme="MASTER_CARD" variant="color" />
                            <PaymentNetworkIcon scheme="AMEX" variant="color" />
                            <Text variant="button-small">
                              <FormattedMessage
                                id="account.payment-scheme.bank-transfer"
                                defaultMessage="Bank transfer"
                              />
                            </Text>
                          </HStack>
                        ) : null}
                      </VStack>
                    ))
                    .with('capture', () => <CaptureSignupButtons />)
                    .exhaustive()}
                </AnimateTransition>
              </AnimateHeight>
            </VStack>
          </VStack>
        </Surface>
      </VStack>
      <ApronFooter />
    </StepLayout.Root>
  )
}
