import type { CardScheme } from 'api/types/cards'
import { FramesCheckout } from 'domains/account/components'
import { useTokenizeCard } from 'domains/account/hooks'
import { toCardScheme } from 'domains/account/utils'
import { useTrackUIView } from 'domains/analytics/hooks'
import { PriceTag } from 'domains/money/components'
import { useMoneyFormat } from 'domains/money/hooks'
import { subtract } from 'domains/money/utils'
import { useGetPaidPayrun, useUpdateGetPaidPayrunAuthor } from 'domains/payrun/queries'
import type { GetPaidPayrun } from 'domains/payrun/types'
import {
  Frames,
  type FramesElementsValidity,
  type FrameElementIdentifer,
} from 'frames-react'
import { StorageKey } from 'kitchen/constants'
import { useForm, Controller, zodResolver } from 'kitchen/forms'
import type { UUID } from 'kitchen/types'
import { assert, createUUID } from 'kitchen/utils/helpers'
import { isCardPaymentFailedError } from 'kitchen/utils/payments'
import { useId, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import {
  InputGroup,
  Input,
  PaymentNetworkIcon,
  Toast,
  StepHeader,
  Surface,
  DataGroup,
  IconButton,
  Tooltip,
  Status,
} from 'salad/components'
import * as Icons from 'salad/icons'
import { StatusLayout, StepLayout } from 'salad/layouts'
import { HStack, Text, VStack } from 'salad/primitives'
import { z } from 'zod'
import { CardPaymentFailedErrorStatus } from '../../../components'
import { Fees } from '../../../constants'
import { PayStepSchema } from '../schemas'
import type { BeneficiaryCompany, PayStepValues } from '../types'
import { usePay } from '../use-pay'

const FIELD_NAME_MAP: Record<FrameElementIdentifer, keyof FramesElementsValidity> = {
  'card-number': 'cardNumber',
  'expiry-date': 'expiryDate',
  cvv: 'cvv',
}

interface Values {
  payrun: GetPaidPayrun
  email: string
  elements: Record<keyof FramesElementsValidity, boolean | null>
  cardholderName: string
}

const truthy = z
  .boolean()
  .nullable()
  .refine((val) => val === true, {
    message: 'Incorrect value',
  })

const validationSchema = PayStepSchema.extend({
  elements: z.object({
    cardNumber: truthy,
    expiryDate: truthy,
    cvv: truthy,
  }),
  cardholderName: z.string().trim().min(1, { message: 'Cardholder name is required' }),
})

interface PayStepProps {
  values: PayStepValues
  beneficiaryCompany: BeneficiaryCompany
  onContinue: (values: PayStepValues) => void
  onBack: () => void
}

export function PayByCardStep({
  values,
  beneficiaryCompany,
  onContinue,
  onBack,
}: PayStepProps) {
  const formId = useId()
  const getPaidPayrun = useGetPaidPayrun(
    { payrunId: values.payrun.id },
    { enabled: false }
  )
  const toast = Toast.useContext()
  const [cardScheme, setCardScheme] = useState<CardScheme>('UNKNOWN')
  const [pay, paymentElement] = usePay({
    onSuccess: async () => {
      const updatedPayrun = await getPaidPayrun.refetch()
      assert(updatedPayrun.data, 'Missing payrun')

      onContinue({
        ...values,
        payrun: updatedPayrun.data,
      })
    },
  })

  useTrackUIView({
    companyId: undefined,
    data: {
      target: 'get-paid-flow',
      step: 'pay',
    },
  })

  const [idempotencyKey, resetIdempotencyKey] = useState(() => createUUID<UUID>())
  const moneyFormat = useMoneyFormat()

  const {
    handleSubmit,
    register,
    control,
    watch,
    formState: { errors },
  } = useForm<Values>({
    resolver: zodResolver(validationSchema),
    defaultValues: {
      ...values,
      elements: { cardNumber: null, expiryDate: null, cvv: null },
    },
  })

  const updateGetPaidPayrunAuthor = useUpdateGetPaidPayrunAuthor({
    onError: () => {
      return toast.show(
        <Toast.Root variant="error">
          <Toast.Title>
            <FormattedMessage
              id="get-paid.payment.update-author.error-toast"
              defaultMessage="Failed to update email address. Please try again later."
            />
          </Toast.Title>
        </Toast.Root>
      )
    },
  })

  const tokenizeCard = useTokenizeCard({
    onError: () => {
      Frames.enableSubmitForm()

      return toast.show(
        <Toast.Root variant="error">
          <Toast.Title>Something went wrong. Please try again later.</Toast.Title>
        </Toast.Root>
      )
    },
  })

  function onError() {
    toast.show(
      <Toast.Root variant="error">
        <Toast.Title>
          Something went wrong. Please try to reload the page and try again
        </Toast.Title>
      </Toast.Root>
    )
  }

  async function handleFormSubmit(values: Values) {
    localStorage.setItem(StorageKey.GET_PAID_PAYER_EMAIL, values.email)

    await updateGetPaidPayrunAuthor.mutateAsync({
      payrunId: values.payrun.id,
      email: values.email,
    })

    const tokenized = await tokenizeCard.mutateAsync({ validate: () => 'OK' })

    pay.mutate({
      payrunId: values.payrun.id,
      token: tokenized.token,
      instrumentType: 'CARD',
      idempotencyKey,
      email: values.email,
      cardholderName: values.cardholderName,
    })
  }

  const cardholderName = watch('cardholderName')

  if (isCardPaymentFailedError(pay.error)) {
    return (
      <StatusLayout.Root>
        <StatusLayout.Content>
          <CardPaymentFailedErrorStatus>
            <Status.Action
              onClick={() => {
                pay.reset()
                tokenizeCard.reset()
                resetIdempotencyKey(createUUID<UUID>())
              }}
            >
              <FormattedMessage id="common.try-again" defaultMessage="Try again" />
            </Status.Action>
          </CardPaymentFailedErrorStatus>
        </StatusLayout.Content>
      </StatusLayout.Root>
    )
  }

  return (
    <StepLayout.Root>
      <StepLayout.Header>
        <StepHeader.Root onBack={onBack}>
          <StepHeader.Title>
            <FormattedMessage
              id="get-paid.payment.pay-by-card-step.title"
              defaultMessage="Pay by card"
            />
          </StepHeader.Title>
          <StepHeader.AmountContainer>
            <PriceTag variant="large" value={values.payrun.amount} />

            <HStack css={{ placeContent: 'center', alignItems: 'center' }}>
              <Text variant="label-16" color="grey-60">
                <FormattedMessage
                  id="get-paid.payment.pay-by-card-step.price-disclaimer"
                  defaultMessage="Includes card processing fee"
                />
              </Text>
              <Tooltip.Root>
                <Tooltip.Trigger asChild>
                  <IconButton size={24} aria-label="Show payment breakdown">
                    <Icons.S16.Info />
                  </IconButton>
                </Tooltip.Trigger>
                <Tooltip.Content variant="unstyled" side="bottom">
                  <Surface variant="popover" p={16} css={{ minWidth: '360px' }}>
                    <VStack
                      gap={12}
                      css={{
                        '[data-group]': {
                          gridAutoFlow: 'column',
                          justifyContent: 'space-between',
                        },
                      }}
                    >
                      <Text variant="title-16">
                        <FormattedMessage
                          id="get-paid.payment.pay-by-card.breakdown"
                          defaultMessage="Payment breakdown"
                        />
                      </Text>
                      <DataGroup.Root>
                        <DataGroup.Label>
                          <FormattedMessage
                            id="get-paid.payment.pay-by-card.payment-amount.label"
                            defaultMessage="Payment to {companyName}"
                            values={{
                              companyName: beneficiaryCompany.legalName,
                            }}
                          />
                        </DataGroup.Label>
                        <DataGroup.Value>
                          {moneyFormat.format(
                            subtract(values.payrun.amount, values.payrun.fee)
                          )}
                        </DataGroup.Value>
                      </DataGroup.Root>
                      <DataGroup.Root>
                        <DataGroup.Label>
                          <FormattedMessage
                            id="get-paid.payment.pay-by-card.processing-fee.label"
                            defaultMessage="Card processing fee ({fee}%)"
                            values={{ fee: Fees.CARD_PROCESSING_FEE_PERCENTAGE }}
                          />
                        </DataGroup.Label>
                        <DataGroup.Value>
                          {moneyFormat.format(values.payrun.fee)}
                        </DataGroup.Value>
                      </DataGroup.Root>
                      <DataGroup.Root>
                        <DataGroup.Label color="black">
                          <FormattedMessage
                            id="get-paid.payment.pay-by-card.total.label"
                            defaultMessage="Your total"
                          />
                        </DataGroup.Label>
                        <DataGroup.Value weight={700}>
                          {moneyFormat.format(values.payrun.amount)}
                        </DataGroup.Value>
                      </DataGroup.Root>
                    </VStack>
                  </Surface>
                </Tooltip.Content>
              </Tooltip.Root>
            </HStack>
          </StepHeader.AmountContainer>
        </StepHeader.Root>
      </StepLayout.Header>
      <VStack gap={16}>
        <Surface
          id={formId}
          p={32}
          variant="flat"
          as="form"
          onSubmit={handleSubmit(handleFormSubmit)}
          onReset={() => {
            tokenizeCard.reset()
          }}
        >
          <VStack gap={24}>
            <InputGroup.Root>
              <InputGroup.Label>
                <FormattedMessage
                  id="get-paid.payment.pay-by-card-step.input-label.email"
                  defaultMessage="Email"
                />
              </InputGroup.Label>
              <Input
                placeholder="example@email.com"
                {...register('email')}
                inputMode="email"
                autoComplete="email"
                autoCapitalize="none"
                aria-invalid={errors.email !== undefined}
              />
              <InputGroup.Message>{errors.email?.message}</InputGroup.Message>
            </InputGroup.Root>

            <InputGroup.Root>
              <InputGroup.Label>
                <FormattedMessage
                  id="get-paid.payment.pay-by-card-step.input-label.cardholder-name"
                  defaultMessage="Cardholder name"
                />
              </InputGroup.Label>
              <Input
                {...register('cardholderName')}
                autoComplete="cc-name"
                placeholder="Enter name"
                aria-invalid={errors.cardholderName !== undefined}
              />
              <InputGroup.Message>{errors.cardholderName?.message}</InputGroup.Message>
            </InputGroup.Root>

            <Controller
              name="elements"
              control={control}
              render={({ field }) => (
                <FramesCheckout.Root
                  config={{
                    cardholder: {
                      name: cardholderName,
                    },
                  }}
                  onError={onError}
                  frameBlur={() => field.onBlur()}
                  cardValidationChanged={(event) => {
                    if (event.isValid) {
                      field.onChange(event.isElementValid)
                    }
                  }}
                  frameValidationChanged={(event) =>
                    field.onChange({
                      ...field.value,
                      [FIELD_NAME_MAP[event.element]]: event.isEmpty
                        ? null
                        : event.isValid,
                    })
                  }
                  cardBinChanged={(event) => setCardScheme(toCardScheme(event.scheme))}
                >
                  <InputGroup.Root>
                    <InputGroup.Label>
                      <FormattedMessage
                        id="get-paid.payment.pay-by-card-step.input-label.card-number"
                        defaultMessage="Card number"
                      />
                    </InputGroup.Label>
                    <InputGroup.Content
                      aria-invalid={errors.elements?.cardNumber?.message !== undefined}
                    >
                      <FramesCheckout.CardNumber />
                    </InputGroup.Content>
                    <InputGroup.End>
                      <PaymentNetworkIcon scheme={cardScheme} />
                    </InputGroup.End>
                    <InputGroup.Message>
                      {errors.elements?.cardNumber?.message}
                    </InputGroup.Message>
                  </InputGroup.Root>
                  <HStack gap={16} css={{ gridAutoColumns: '1fr' }}>
                    <InputGroup.Root>
                      <InputGroup.Label>
                        <FormattedMessage
                          id="get-paid.payment.pay-by-card-step.input-label.expiry-date"
                          defaultMessage="Expiry date"
                        />
                      </InputGroup.Label>
                      <InputGroup.Content
                        aria-invalid={errors.elements?.expiryDate?.message !== undefined}
                      >
                        <FramesCheckout.ExpiryDate />
                      </InputGroup.Content>
                      <InputGroup.Message>
                        {errors.elements?.expiryDate?.message}
                      </InputGroup.Message>
                    </InputGroup.Root>
                    <InputGroup.Root>
                      <InputGroup.Label>
                        <FormattedMessage
                          id="get-paid.payment.pay-by-card-step.input-label.cvv"
                          defaultMessage="Security code"
                        />
                      </InputGroup.Label>
                      <InputGroup.Content
                        aria-invalid={errors.elements?.cvv?.message !== undefined}
                      >
                        <FramesCheckout.Cvv />
                      </InputGroup.Content>
                      <InputGroup.Message>
                        {errors.elements?.cvv?.message}
                      </InputGroup.Message>
                    </InputGroup.Root>
                  </HStack>
                </FramesCheckout.Root>
              )}
            />
          </VStack>
        </Surface>
      </VStack>

      <StepLayout.Action
        type="submit"
        form={formId}
        loading={tokenizeCard.isLoading || pay.isLoading}
      >
        <FormattedMessage
          id="get-paid.payment.pay-by-card-step.submit"
          defaultMessage="Pay"
        />
      </StepLayout.Action>
      {paymentElement}
    </StepLayout.Root>
  )
}
