import { useControlled } from 'kitchen/hooks/use-controlled'
import { Popover as PopoverBase } from 'radix-ui'
import { useMemo, useContext } from 'react'
import { PopoverContext, type PopoverContextValue } from '../../context/popover-context'
import { styled, keyframes, theme } from '../../stitches'

export interface RenderPopoverChildren {
  (context: PopoverContextValue): React.ReactNode
}

function usePopoverContext() {
  return useContext(PopoverContext)
}

export interface PopoverProps extends PopoverBase.PopoverProps {}

function Popover({ defaultOpen = false, open, onOpenChange, ...rest }: PopoverProps) {
  const [isOpen, setOpen] = useControlled<boolean>({
    defaultValue: defaultOpen,
    value: open,
    onChange: onOpenChange,
  })

  const contextValue = useMemo(() => ({ isOpen: isOpen, setOpen }), [isOpen, setOpen])

  return (
    <PopoverContext.Provider value={contextValue}>
      <PopoverBase.Root open={isOpen} onOpenChange={setOpen} {...rest} />
    </PopoverContext.Provider>
  )
}

const PopoverTriggerBase = styled(PopoverBase.Trigger, {
  color: 'inherit',
  '&:enabled': {
    cursor: 'pointer',
  },
})

export interface PopoverTriggerProps
  extends Omit<React.ComponentProps<typeof PopoverTriggerBase>, 'children'> {
  children?: RenderPopoverChildren | React.ReactNode
}

const PopoverTrigger = ({ children, ...rest }: PopoverTriggerProps) => {
  const contextValue = usePopoverContext()
  if (contextValue === null) {
    throw new Error('Popover.Trigger must be used within Popover.Root')
  }
  return (
    <PopoverTriggerBase {...rest}>
      {typeof children === 'function' ? children(contextValue) : children}
    </PopoverTriggerBase>
  )
}

const fadeIn = keyframes({
  from: { opacity: 0 },
  to: { opacity: 1 },
})

const fadeOut = keyframes({
  from: { opacity: 1 },
  to: { opacity: 0 },
})

const PopoverContentBase = styled(PopoverBase.Content, {
  '&[data-state="open"]': {
    animation: `${fadeIn} 0.15s ease-out`,
  },
  '&[data-state="closed"]': {
    animation: `${fadeOut} 0.15s ease-out`,
  },
})

export interface PopoverContentProps
  extends Omit<React.ComponentProps<typeof PopoverContentBase>, 'children'> {
  size?: number
  children?: RenderPopoverChildren | React.ReactNode
}

function PopoverContent({
  forceMount,
  children,
  size,
  css,
  ...rest
}: PopoverContentProps) {
  const contextValue = usePopoverContext()
  if (contextValue === null) {
    throw new Error('Popover.Content must be used within Popover.Root')
  }

  return (
    <PopoverBase.Portal forceMount={forceMount}>
      <PopoverContentBase
        forceMount={forceMount}
        sideOffset={6}
        css={
          size
            ? {
                width: `min(${size}px, 100vw - ${theme.space[16]})`,
                ...css,
              }
            : css
        }
        {...rest}
      >
        {typeof children === 'function' ? children(contextValue) : children}
      </PopoverContentBase>
    </PopoverBase.Portal>
  )
}

const PopoverAnchorBase = styled(PopoverBase.Anchor)

export interface PopoverAnchorProps
  extends Omit<React.ComponentProps<typeof PopoverAnchorBase>, 'children'> {
  children?: RenderPopoverChildren | React.ReactNode
}

const PopoverAnchor = ({ children, ...rest }: PopoverAnchorProps) => {
  const contextValue = usePopoverContext()

  if (contextValue === null) {
    throw new Error('Popover.Anchor must be used within Popover.Root')
  }

  return (
    <PopoverAnchorBase {...rest}>
      {typeof children === 'function' ? children(contextValue) : children}
    </PopoverAnchorBase>
  )
}

const PopoverArrow = styled(PopoverBase.Arrow)
const PopoverClose = styled(PopoverBase.Close)

/**
 * available in CSS within PopoverContext
 */
const availablePopoverContentHeight = 'var(--radix-popover-content-available-height, 0px)'
const popoverTriggerWidth = 'var(--radix-popover-trigger-width, 0px)'

export {
  Popover as Root,
  PopoverTrigger as Trigger,
  PopoverAnchor as Anchor,
  PopoverArrow as Arrow,
  PopoverClose as Close,
  PopoverContent as Content,
  usePopoverContext as useContext,
  availablePopoverContentHeight as availableContentHeight,
  popoverTriggerWidth as triggerWidth,
}
